import { useState, useEffect, useRef } from "react";
import "../../resources/Spinner/css/the_game.css";
import AudioPlayer from "./AudioPlayer";
// import videoBg from "../resources/img/transparent_vd.mp4";
import audioPath from "../../resources/Spinner/mixkit-antique-movie-projector-spinning-1444.wav";

const arrow_img = require("../../resources/Spinner/img/arrow.png");
const arrow_logo = require("../../resources/Spinner/img/top logo.png");
const arrow_logo_bottom = require("../../resources/Spinner/img/site_logo.png");
// const site_logo = require("../resources/img/site_logo.png");

var initial_position = "0.00";

var openStatus = "closed";
const onePart = 360 / 37;

var one_twenty_array = [];

const { base_url } = require("../../constants/index.js")

const TheGame = () => {
    const spinRef = useRef(null);
    const audioRef = useRef(null);
    
    const [blankCenterNumber, setBlankCenterNumber] = useState(true);

    const [rotateTransform, setRotateTransform] = useState("none");
    const [rotateTransition, setRotateTransition] = useState("none");
    const [initialPosition, setInitialPosition] = useState("0.00");
    const [initialSectorLevel, setInitialSectorLevel] = useState(0);
    const [centerNumber, setCenterNumber] = useState("");
    const [centerBigNumber, setCenterBigNumber] = useState(false);
    const [centerColor, setCenterColor] = useState("black");
    const [centerBackColor, setCenterBackColor] = useState("green");
    const [timeRemaining, setTimeRemaining] = useState("BET IS CLOSED");
    const [currentGameLength, setCurrentGameLength] = useState(30000);
    const [currentGameId, setCurrentGameId] = useState("NONE");
    const [currentNumber, setCurrentNumber] = useState(0);
    const [oneTwentyArray, setOneTwentyArray] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [oneTwentyColors, setOneTwentyColors] = useState([]);
    const [oneTwentyEvenOdd, setOneTwentyEvenOdd] = useState([]);
    const [oneTwentyTwins, setOneTwentyTwins] = useState([]);
    const [oneTwentySectors, setOneTwentySectors] = useState([]);
    const [oneTwentyMirrors, setOneTwentyMirrors] = useState([]);
    const [oneTwentyHighLow, setOneTwentyHighLow] = useState([]);
    const [dozens, setDozens] = useState([]);
    const [oneTwentyHighLowColor, setOneTwentyHighLowColor] = useState([]);
    const [oneTwentyHotColdNums, setOneTwentyHotColdNums] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [lastTenNumbers, setLastTenNumbers] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [lastTenDrawNumbers, setLastTenDrawNumbers] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [spin_numbers_array] = useState([
        1, 13, 25, 2, 14, 26, 3, 15, 27, 4, 16, 28, 5, 17, 29, 6, 18, 30, 7, 19, 31,
        8, 20, 32, 9, 21, 33, 10, 22, 34, 11, 23, 35, 12, 24, 36,
    ]);
    //var db_random_number = 1;
    //var db_winner_number = 1;
    const roundNumber = (numb) => {
        var rounded = Math.round((numb + Number.EPSILON) * 100) / 100;
        return rounded;
    };

    // useEffect(() => {
    //   playAudio();
    // }, []);

    // const clockElement = document.getElementById("clock");

    // function updateTimeRemaining() {
    //   if (timeRemaining === "BET IS CLOSED") {
    //     clockElement.textContent = "BET CLOSED";
    //   } else {
    //     const remainingTime = seconds_to_clock(timeRemaining);
    //     clockElement.textContent = remainingTime;

    //     if (timeRemaining < 10) {
    //       clockElement.classList.add("blink");
    //     } else {
    //       clockElement.classList.remove("blink");
    //     }
    //   }
    // }

    // // Call updateTimeRemaining whenever timeRemaining changes
    // updateTimeRemaining();

    //----------------------------------------------------new functions from backend------------------------------------------------------
    //----------------------------------------------------new functions from backend------------------------------------------------------
    //----------------------------------------------------new functions from backend------------------------------------------------------
    //----------------------------------------------------new functions from backend------------------------------------------------------
    const spin_numbers2 = {
        0: { index: 0, level: 0, deg: "0.00", color: "green", sector: "none" },
        1: {
            index: 1,
            level: 14,
            deg: roundNumber(onePart * 14).toString(),
            color: "red",
            sector: "D",
        },
        2: {
            index: 2,
            level: 31,
            deg: roundNumber(onePart * 31).toString(),
            color: "black",
            sector: "A",
        },
        3: {
            index: 3,
            level: 2,
            deg: roundNumber(onePart * 2).toString(),
            color: "red",
            sector: "F",
        },
        4: {
            index: 4,
            level: 33,
            deg: roundNumber(onePart * 33).toString(),
            color: "black",
            sector: "A",
        },
        5: {
            index: 5,
            level: 18,
            deg: roundNumber(onePart * 18).toString(),
            color: "red",
            sector: "D",
        },
        6: {
            index: 6,
            level: 27,
            deg: roundNumber(onePart * 27).toString(),
            color: "black",
            sector: "B",
        },
        7: {
            index: 7,
            level: 6,
            deg: roundNumber(onePart * 6).toString(),
            color: "red",
            sector: "F",
        },
        8: {
            index: 8,
            level: 21,
            deg: roundNumber(onePart * 21).toString(),
            color: "black",
            sector: "C",
        },
        9: {
            index: 9,
            level: 10,
            deg: roundNumber(onePart * 10).toString(),
            color: "red",
            sector: "E",
        },
        10: {
            index: 10,
            level: 19,
            deg: roundNumber(onePart * 19).toString(),
            color: "black",
            sector: "C",
        },
        11: {
            index: 11,
            level: 23,
            deg: roundNumber(onePart * 23).toString(),
            color: "black",
            sector: "C",
        },
        12: {
            index: 12,
            level: 4,
            deg: roundNumber(onePart * 4).toString(),
            color: "red",
            sector: "F",
        },
        13: {
            index: 13,
            level: 25,
            deg: roundNumber(onePart * 25).toString(),
            color: "black",
            sector: "B",
        },
        14: {
            index: 14,
            level: 12,
            deg: roundNumber(onePart * 12).toString(),
            color: "red",
            sector: "E",
        },
        15: {
            index: 15,
            level: 35,
            deg: roundNumber(onePart * 35).toString(),
            color: "black",
            sector: "A",
        },
        16: {
            index: 16,
            level: 16,
            deg: roundNumber(onePart * 16).toString(),
            color: "red",
            sector: "D",
        },
        17: {
            index: 17,
            level: 29,
            deg: roundNumber(onePart * 29).toString(),
            color: "black",
            sector: "B",
        },
        18: {
            index: 18,
            level: 8,
            deg: roundNumber(onePart * 8).toString(),
            color: "red",
            sector: "E",
        },
        19: {
            index: 19,
            level: 34,
            deg: roundNumber(onePart * 34).toString(),
            color: "red",
            sector: "A",
        },
        20: {
            index: 20,
            level: 13,
            deg: roundNumber(onePart * 13).toString(),
            color: "black",
            sector: "D",
        },
        21: {
            index: 21,
            level: 32,
            deg: roundNumber(onePart * 32).toString(),
            color: "red",
            sector: "A",
        },
        22: {
            index: 22,
            level: 9,
            deg: roundNumber(onePart * 9).toString(),
            color: "black",
            sector: "E",
        },
        23: {
            index: 23,
            level: 20,
            deg: roundNumber(onePart * 20).toString(),
            color: "red",
            sector: "C",
        },
        24: {
            index: 24,
            level: 17,
            deg: roundNumber(onePart * 17).toString(),
            color: "black",
            sector: "D",
        },
        25: {
            index: 25,
            level: 30,
            deg: roundNumber(onePart * 30).toString(),
            color: "red",
            sector: "B",
        },
        26: {
            index: 26,
            level: 1,
            deg: roundNumber(onePart * 1).toString(),
            color: "black",
            sector: "F",
        },
        27: {
            index: 27,
            level: 26,
            deg: roundNumber(onePart * 26).toString(),
            color: "red",
            sector: "B",
        },
        28: {
            index: 28,
            level: 5,
            deg: roundNumber(onePart * 5).toString(),
            color: "black",
            sector: "F",
        },
        29: {
            index: 29,
            level: 7,
            deg: roundNumber(onePart * 7).toString(),
            color: "black",
            sector: "E",
        },
        30: {
            index: 30,
            level: 22,
            deg: roundNumber(onePart * 22).toString(),
            color: "red",
            sector: "C",
        },
        31: {
            index: 31,
            level: 11,
            deg: roundNumber(onePart * 11).toString(),
            color: "black",
            sector: "E",
        },
        32: {
            index: 32,
            level: 36,
            deg: roundNumber(onePart * 36).toString(),
            color: "red",
            sector: "A",
        },
        33: {
            index: 33,
            level: 15,
            deg: roundNumber(onePart * 15).toString(),
            color: "black",
            sector: "D",
        },
        34: {
            index: 34,
            level: 28,
            deg: roundNumber(onePart * 28).toString(),
            color: "red",
            sector: "B",
        },
        35: {
            index: 35,
            level: 3,
            deg: roundNumber(onePart * 3).toString(),
            color: "black",
            sector: "F",
        },
        36: {
            index: 36,
            level: 24,
            deg: roundNumber(onePart * 24).toString(),
            color: "red",
            sector: "C",
        },
    };

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    //-----------------------------------------------------end of new functions from backend-----------------------------------------------
    //-----------------------------------------------------end of new functions from backend-----------------------------------------------
    //-----------------------------------------------------end of new functions from backend-----------------------------------------------
    //-----------------------------------------------------end of new functions from backend-----------------------------------------------

    const onTransitionEnd = () => {
        setHasUserInteracted(true);
        setRotateTransform(`rotate(${initialPosition}deg)`);
        setRotateTransition("none");
        setCenterBigNumber(true);
        fetch_one_twenty_rows();
        last_ten_rows();
        one_twenty_hot_cold();
    };

    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        // Add event listener to detect when fullscreen mode changes
        document.addEventListener("fullscreenchange", handleFullScreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
        };
    }, []);
    const [hasUserInteracted, setHasUserInteracted] = useState(true);

    useEffect(() => {
        const handleInteraction = () => {
            setHasUserInteracted(true);
        };

        window.addEventListener("click", handleInteraction);

        return () => {
            window.removeEventListener("click", handleInteraction);
        };
    }, []);

    useEffect(() => {
        if (hasUserInteracted) {
            if(audioRef && audioRef?.current)
            audioRef.current.play();
        }
    }, [hasUserInteracted, audioRef]);

    const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            // Request full-screen mode
            document.documentElement.requestFullscreen();
        } else {
            // Exit full-screen mode
            document.exitFullscreen();
        }
    };

    const spin_numbers = {
        0: { index: 0, level: 0, deg: "0.00", color: "green" },
        1: {
            index: 26,
            level: 1,
            deg: roundNumber(onePart * 1).toString(),
            color: "black",
            sector: "F",
        },
        2: {
            index: 3,
            level: 2,
            deg: roundNumber(onePart * 2).toString(),
            color: "red",
            sector: "F",
        },
        3: {
            index: 35,
            level: 3,
            deg: roundNumber(onePart * 3).toString(),
            color: "black",
            sector: "F",
        },
        4: {
            index: 12,
            level: 4,
            deg: roundNumber(onePart * 4).toString(),
            color: "red",
            sector: "F",
        },
        5: {
            index: 28,
            level: 5,
            deg: roundNumber(onePart * 5).toString(),
            color: "black",
            sector: "F",
        },
        6: {
            index: 7,
            level: 6,
            deg: roundNumber(onePart * 6).toString(),
            color: "red",
            sector: "F",
        },
        7: {
            index: 29,
            level: 7,
            deg: roundNumber(onePart * 7).toString(),
            color: "black",
            sector: "E",
        },
        8: {
            index: 18,
            level: 8,
            deg: roundNumber(onePart * 8).toString(),
            color: "red",
            sector: "E",
        },
        9: {
            index: 22,
            level: 9,
            deg: roundNumber(onePart * 9).toString(),
            color: "black",
            sector: "E",
        },
        10: {
            index: 9,
            level: 10,
            deg: roundNumber(onePart * 10).toString(),
            color: "red",
            sector: "E",
        },
        11: {
            index: 31,
            level: 11,
            deg: roundNumber(onePart * 11).toString(),
            color: "black",
            sector: "E",
        },
        12: {
            index: 14,
            level: 12,
            deg: roundNumber(onePart * 12).toString(),
            color: "red",
            sector: "E",
        },
        13: {
            index: 20,
            level: 13,
            deg: roundNumber(onePart * 13).toString(),
            color: "black",
            sector: "D",
        },
        14: {
            index: 1,
            level: 14,
            deg: roundNumber(onePart * 14).toString(),
            color: "red",
            sector: "D",
        },
        15: {
            index: 33,
            level: 15,
            deg: roundNumber(onePart * 15).toString(),
            color: "black",
            sector: "D",
        },
        16: {
            index: 16,
            level: 16,
            deg: roundNumber(onePart * 16).toString(),
            color: "red",
            sector: "D",
        },
        17: {
            index: 24,
            level: 17,
            deg: roundNumber(onePart * 17).toString(),
            color: "black",
            sector: "D",
        },
        18: {
            index: 5,
            level: 18,
            deg: roundNumber(onePart * 18).toString(),
            color: "red",
            sector: "D",
        },
        19: {
            index: 10,
            level: 19,
            deg: roundNumber(onePart * 19).toString(),
            color: "black",
            sector: "C",
        },
        20: {
            index: 23,
            level: 20,
            deg: roundNumber(onePart * 20).toString(),
            color: "red",
            sector: "C",
        },
        21: {
            index: 8,
            level: 21,
            deg: roundNumber(onePart * 21).toString(),
            color: "black",
            sector: "C",
        },
        22: {
            index: 30,
            level: 22,
            deg: roundNumber(onePart * 22).toString(),
            color: "red",
            sector: "C",
        },
        23: {
            index: 11,
            level: 23,
            deg: roundNumber(onePart * 23).toString(),
            color: "black",
            sector: "C",
        },
        24: {
            index: 36,
            level: 24,
            deg: roundNumber(onePart * 24).toString(),
            color: "red",
            sector: "C",
        },
        25: {
            index: 13,
            level: 25,
            deg: roundNumber(onePart * 25).toString(),
            color: "black",
            sector: "B",
        },
        26: {
            index: 27,
            level: 26,
            deg: roundNumber(onePart * 26).toString(),
            color: "red",
            sector: "B",
        },
        27: {
            index: 6,
            level: 27,
            deg: roundNumber(onePart * 27).toString(),
            color: "black",
            sector: "B",
        },
        28: {
            index: 34,
            level: 28,
            deg: roundNumber(onePart * 28).toString(),
            color: "red",
            sector: "B",
        },
        29: {
            index: 17,
            level: 29,
            deg: roundNumber(onePart * 29).toString(),
            color: "black",
            sector: "B",
        },
        30: {
            index: 25,
            level: 30,
            deg: roundNumber(onePart * 30).toString(),
            color: "red",
            sector: "B",
        },
        31: {
            index: 2,
            level: 31,
            deg: roundNumber(onePart * 31).toString(),
            color: "black",
            sector: "A",
        },
        32: {
            index: 21,
            level: 32,
            deg: roundNumber(onePart * 32).toString(),
            color: "red",
            sector: "A",
        },
        33: {
            index: 4,
            level: 33,
            deg: roundNumber(onePart * 33).toString(),
            color: "black",
            sector: "A",
        },
        34: {
            index: 19,
            level: 34,
            deg: roundNumber(onePart * 34).toString(),
            color: "red",
            sector: "A",
        },
        35: {
            index: 15,
            level: 35,
            deg: roundNumber(onePart * 35).toString(),
            color: "black",
            sector: "A",
        },
        36: {
            index: 32,
            level: 36,
            deg: roundNumber(onePart * 36).toString(),
            color: "red",
            sector: "A",
        },
    };

    function get_current_game_length() {
        const url = `${base_url}spinner/all_tables/users/getgamelength`;
        const res = fetch(url, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        //  console.log(json);
                        setCurrentGameLength(json.game_length);
                        //  setFetchedData(json);
                    });
                } else {
                    // console.log("not fetched");
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //     console.log("not fetched 2");
            });
    }

    const startSpin = (winner_number, random_number) => {
        playAudio();
        setBlankCenterNumber(false);
        setRotateTransform(`rotate(${roundNumber(random_number * onePart)}deg)`);

        setRotateTransition("transform 25s ease 0s");
 
        console.log('onePart');
        console.log(onePart);
        console.log('onePart-originalLevel');
        console.log(random_number);
        console.log((random_number * onePart - 3960) / onePart);
        console.log(Math.round((random_number * onePart - 3960) / onePart));
        const originalLevel = Math.round(
            (random_number * onePart - 3960) / onePart
        );
        // console.log(originalLevel);
        // console.log(spin_numbers[originalLevel].index);
        console.log('spin_numbers[originalLevel]');
        console.log(spin_numbers[originalLevel]);
        console.log('originalLevel');
        console.log(originalLevel);
        console.log('spin_numbers[originalLevel]');
        console.log(spin_numbers[originalLevel]);
        setInitialPosition(spin_numbers[originalLevel].deg);
        //initial_position = spin_numbers[originalLevel].deg;
        setInitialSectorLevel(spin_numbers[originalLevel].level);
        setCurrentNumber(winner_number);
        //fetch_data_first();
        //console.log("in startSpin " + winner_number);
        //update_winner_and_tickets(winner_number, random_number, game_id);
    };

    function getRotationAngle(target) {
        const obj = window.getComputedStyle(target, null);
        const matrix =
            obj.getPropertyValue("-webkit-transform") ||
            obj.getPropertyValue("-moz-transform") ||
            obj.getPropertyValue("-ms-transform") ||
            obj.getPropertyValue("-o-transform") ||
            obj.getPropertyValue("transform");

        let angle = 0;

        if (matrix !== "none") {
            const values = matrix.split("(")[1].split(")")[0].split(",");
            const a = values[0];
            const b = values[1];
            angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        }

        return angle < 0 ? (angle += 360) : angle;
    }

    //the timer to first load

    //end of the timer to first load
    function fetch_data_intro() {
        //----------------------------fetching open status and time remaining---------------------------
        // const url_open_status = `${base_url}spinner/all_tables/users/getopenstatus`;
        const url_open_status = `${base_url}spinner/all_tables/users/getopenstatus`;

        const res = fetch(url_open_status)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        // console.log(json);
                        if (json.open_status == "closed") {
                            setTimeRemaining("BET IS CLOSED");
                            // start spinner
                            setCenterBigNumber(false);

                            //end of start spinner
                            openStatus = "closed";

                            //  console.log("to fetch data intro");
                            setTimeout(() => {
                                fetch_data_intro();
                            }, 900);
                        } else {
                            //means open

                            setTimeRemaining(json.time_remaining);
                            //openStatus = "open";
                            setCenterBigNumber(false);
                            // console.log("to fetch data first");
                            fetch_data_first();
                        }

                        // console.log("fetched");
                    });
                } else {
                    // console.log("not fetched");
                    setTimeout(() => {
                        fetch_data_intro();
                    }, 900);
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //     console.log("not fetched 2");
                setTimeout(() => {
                    fetch_data_intro();
                }, 900);
            });
        //---------------------------end of fetching open status and time remaining---------------------
    }

    //the timer to fetch data one
    function fetch_data_first() {
        //----------------------------fetching open status and time remaining---------------------------
        // const url_open_status = `${base_url}spinner/all_tables/users/getopenstatus`;
        const url_open_status = `${base_url}spinner/all_tables/users/getopenstatus`;

        const res = fetch(url_open_status)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        if (json.open_status == "closed") {
                            setTimeRemaining("BET IS CLOSED");
                            // start spinner
                            if (openStatus != "closed") {
                                console.log("closed changed");
                                //setCurrentNumber(json.winner_number);
                                openStatus = "closed";
                                startSpin(json.winner_number, json.random_number);
                                //  fetch_data_first();
                            } else {
                                openStatus = "closed";
                                // fetch_data_first();
                            }

                            setTimeout(() => {
                                fetch_data_first();
                            }, 900);

                            //end of start spinner
                        } else {
                            //means it is open
                            setTimeRemaining(json.time_remaining);
                            if (openStatus == "closed") {
                                // console.log("open changed");
                                get_current_game_length();
                                // db_random_number = json.random_number;
                                // db_winner_number = json.winner_number;
                                openStatus = "open";
                                setCurrentGameId(json.current_game_id);

                                setCenterBigNumber(false);
                            }
                            // setCenterBigNumber(false);

                            openStatus = "open";
                            setCenterBigNumber(false);
                            // fetch_data_first();
                            setTimeout(() => {
                                fetch_data_first();
                            }, 900);
                        }
                        //console.log("to fetch data second");

                        // console.log("fetched");
                    });
                } else {
                    // console.log("not fetched");
                    console.log("error 1");
                    // fetch_data_first();
                    setTimeout(() => {
                        fetch_data_first();
                    }, 900);
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //     console.log("not fetched 2");
                console.log("error 2");
                // fetch_data_first();
                setTimeout(() => {
                    fetch_data_first();
                }, 900);
            });
        //---------------------------end of fetching open status and time remaining---------------------
    }

    function seconds_to_clock(duration) {
        if (duration < 0) {
            duration = 0;
        }
        var time = duration / 1000;
        // var hours = Math.floor(time / 3600);
        // time -= hours * 3600;

        var minutes = Math.floor(time / 60);
        time -= minutes * 60;

        var seconds = parseInt(time % 60, 10);

        //console.log(hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
        return (
            (minutes < 10 ? "0" + minutes : minutes) +
            ":" +
            (seconds < 10 ? "0" + seconds : seconds)
        );
    }

    const playAudio = () => {
        const audio = audioRef.current;
        if (audio) {
            audio.play().catch((error) => {
                // Handle audio playback error
                console.error("Error playing audio:", error);
            });
        }
    };

    // spinRef.current.addEventListener("transitionend", function(e){
    //   onTransitionEnd();
    // });

    function last_ten_rows() {
        const last_ten = [];
        const url_one_twenty = `${base_url}spinner/all_tables/users/lasttenrows`;

        const res = fetch(url_one_twenty)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        // console.log(json);
                        if (json.length >= 10) {
                            for (var j = 0; j < json.length; j++) {
                                last_ten.push(json[j].winner_number);
                            }
                        } else {
                            for (var j = 0; j < 10; j++) {
                                last_ten.push(0);
                            }
                        }

                        setLastTenNumbers(last_ten);
                        setLastTenDrawNumbers(json);
                        //  console.log(last_ten);
                        // console.log(one_twenty_array);
                        //console.log('fetched');
                    });
                } else {
                    console.log("not fetched");
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                console.log("not fetched 2");
            });
    }
    function fetch_one_twenty_rows() {
        //var one_twenty_arr = [];
        const url_one_twenty = `${base_url}spinner/all_tables/users/onetwentyrows`;

        const res = fetch(url_one_twenty)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        one_twenty_array = [];
                        //  console.log(json);
                        for (var i = 0; i < 37; i++) {
                            var count = 0;
                            var count_amount = 0;
                            for (var j = 0; j < json.length; j++) {
                                if (json[j].winner_number * 1 == i) {
                                    one_twenty_array.push(json[j].count_amount);

                                    count = 1;
                                }
                            }

                            if (count == 0) {
                                one_twenty_array.push(0);
                            }
                        }
                        // console.log(one_twenty_arr);
                        //console.log('fetched');
                        // return one_twenty_arr;
                        //console.log(get_colors(one_twenty_array));
                        // console.log(one_twenty_array);

                        setOneTwentyArray(one_twenty_array);
                        get_colors(one_twenty_array);
                        get_one_twenty_even_odd(one_twenty_array);
                        get_one_twenty_twins(one_twenty_array);
                        get_one_twenty_sectors(one_twenty_array);
                        get_one_twenty_mirrors(one_twenty_array);
                        one_twenty_high_low(one_twenty_array);
                        one_twenty_dozens(one_twenty_array);
                        one_twenty_high_low_color(one_twenty_array);
                    });
                } else {
                    //return one_twenty_arr;
                    console.log("not fetched");
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //return one_twenty_arr;
                console.log("not fetched 2");
            });
        //---------------------------end of fetching open status and time remaining---------------------
    }

    //--------------------------------get colors--------------------------------------------
    function get_colors(one_twenty_array) {
        var one_twenty_colors = [];
        var red = 0;
        var black = 0;
        var green = 0;
        for (let i = 0; i < 37; i++) {
            if (spin_numbers2[i].color == "red") {
                red += one_twenty_array[i];
            } else if (spin_numbers2[i].color == "black") {
                black += one_twenty_array[i];
            } else if (spin_numbers2[i].color == "green") {
                green += one_twenty_array[i];
            }
        }

        one_twenty_colors.push(red);
        one_twenty_colors.push(black);
        one_twenty_colors.push(green);
        setOneTwentyColors(one_twenty_colors);
        return one_twenty_colors;
    }
    //------------------------------end of get colors--------------------------------------
    //----------------------------get even odd--------------------------------------------
    function get_one_twenty_even_odd(one_twenty_array) {
        var even_odd = [];
        var even_num = 0;
        var odd_num = 0;

        for (var i = 1; i < 37; i++) {
            if (i % 2 == 0) {
                even_num += one_twenty_array[i];
            } else {
                odd_num += one_twenty_array[i];
            }
        }

        even_odd.push(even_num);
        even_odd.push(odd_num);
        setOneTwentyEvenOdd(even_odd);
        return even_odd;
    }
    //-----------------------------end of get even odd---------------------------------------
    //----------------------------get one twenty twins---------------------------------------
    function get_one_twenty_twins(one_twenty_array) {
        var twins = [];
        var twins_count = 0;
        for (var i = 0; i < 37; i++) {
            if (i == 11 || i == 22 || i == 33) {
                twins_count += one_twenty_array[i];
            }
        }
        twins.push(twins_count);
        setOneTwentyTwins(twins);
        return twins;
    }
    //--------------------------end of get one twenty twins--------------------------------------
    //---------------------------get one twenty sectors count------------------------------------
    function get_one_twenty_sectors(one_twenty_array) {
        var one_twenty_sectors = [];
        var a = 0;
        var b = 0;
        var c = 0;
        var d = 0;
        var e = 0;
        var f = 0;
        for (var i = 0; i < 37; i++) {
            if (spin_numbers2[i].sector == "A") {
                a += one_twenty_array[i];
            } else if (spin_numbers2[i].sector == "B") {
                b += one_twenty_array[i];
            } else if (spin_numbers2[i].sector == "C") {
                c += one_twenty_array[i];
            } else if (spin_numbers2[i].sector == "D") {
                d += one_twenty_array[i];
            } else if (spin_numbers2[i].sector == "E") {
                e += one_twenty_array[i];
            } else if (spin_numbers2[i].sector == "F") {
                f += one_twenty_array[i];
            }
        }

        one_twenty_sectors.push(a);
        one_twenty_sectors.push(b);
        one_twenty_sectors.push(c);
        one_twenty_sectors.push(d);
        one_twenty_sectors.push(e);
        one_twenty_sectors.push(f);
        setOneTwentySectors(one_twenty_sectors);
        return one_twenty_sectors;
    }

    //------------------------------end of one twenty sectors-----------------------------------
    //--------------------------------get one twenty mirror----------------------------------------
    function get_one_twenty_mirrors(one_twenty_array) {
        var one_twenty_mirrors = [];
        var one_two = 0;
        var one_three = 0;
        var two_three = 0;
        for (var i = 0; i < 37; i++) {
            if (i == 12 || i == 21) {
                one_two += one_twenty_array[i];
            } else if (i == 13 || i == 31) {
                one_three += one_twenty_array[i];
            } else if (i == 23 || i == 32) {
                two_three += one_twenty_array[i];
            }
        }

        one_twenty_mirrors.push(one_two);
        one_twenty_mirrors.push(one_three);
        one_twenty_mirrors.push(two_three);
        setOneTwentyMirrors(one_twenty_mirrors);
        return one_twenty_mirrors;
    }
    //---------------------------end of get one twenty mirror ---------------------------------------
    //-------------------------------one twenty high low--------------------------------------------
    function one_twenty_high_low(one_twenty_array) {
        var high_low = [];
        var high_num = 0;
        var low_num = 0;

        for (var i = 1; i < 37; i++) {
            if (i > 18) {
                high_num += one_twenty_array[i];
            } else {
                low_num += one_twenty_array[i];
            }
        }

        high_low.push(high_num);
        high_low.push(low_num);
        setOneTwentyHighLow(high_low);
        return high_low;
    }

    function one_twenty_dozens(one_twenty_array) {
        var rounds = [];
        var round1 = 0;
        var round2 = 0;
        var round3 = 0;

        for (var i = 1; i < 37; i++) {
            if (i <= 12 && i > 1) {
                round1 += one_twenty_array[i];
            } else if (i <= 24 && i > 12) {
                round2 += one_twenty_array[i];
            } else {
                round3 += one_twenty_array[i];
            }
        }

        rounds.push(round1);
        rounds.push(round2);
        rounds.push(round3);
        setDozens(rounds);
        return rounds;
    }
    //----------------------------------end of twenty high low--------------------------------------
    //---------------------------------one twenty high low color-------------------------------------
    function one_twenty_high_low_color(one_twenty_array) {
        var high_low_color = [];
        var high_num_red = 0;
        var high_num_black = 0;
        var low_num_red = 0;
        var low_num_black = 0;

        for (var i = 1; i < 37; i++) {
            if (i > 18) {
                if (spin_numbers2[i].color == "red") {
                    high_num_red += one_twenty_array[i];
                } else {
                    high_num_black += one_twenty_array[i];
                }
            } else {
                if (spin_numbers2[i].color == "red") {
                    low_num_red += one_twenty_array[i];
                } else {
                    low_num_black += one_twenty_array[i];
                }
            }
        }

        high_low_color.push(high_num_red);
        high_low_color.push(low_num_red);
        high_low_color.push(high_num_black);
        high_low_color.push(low_num_black);

        setOneTwentyHighLowColor(high_low_color);
        return high_low_color;
    }
    //---------------------------------end of one twenty high low color----------------------------------
    //----------------------------------hot cold --------------------------------------------------------
    function one_twenty_hot_cold() {
        var hot_cold_nums = [];
        var sorted_array = [];
        var hot_nums = [];
        var cold_nums = [];

        const url_one_twenty = `${base_url}spinner/all_tables/users/hotcold`;

        const res = fetch(url_one_twenty)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        // console.log(json);

                        for (var i = 0; i < 37; i++) {
                            var count = 0;

                            for (var j = 0; j < json.length; j++) {
                                if (i == json[j].winner_number) {
                                    count = 1;
                                }
                            }

                            if (count == 0) {
                                sorted_array.push(i);
                            }
                        }

                        for (var j = 0; j < json.length; j++) {
                            sorted_array.push(json[j].winner_number);
                        }

                        for (var x = 0; x < sorted_array.length; x++) {
                            if (x < 6) {
                                cold_nums.push(sorted_array[x]);
                            }
                            if (x > 30) {
                                hot_nums.push(sorted_array[x]);
                            }
                        }

                        hot_cold_nums.push(hot_nums);
                        hot_cold_nums.push(cold_nums);

                        // setOneTwentyHotColdNums(hot_cold_nums);
                        setOneTwentyHotColdNums(sorted_array);
                        // console.log(one_twenty_array);
                        //console.log('fetched');
                    });
                } else {
                    console.log("not fetched");
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                console.log("not fetched 2");
            });
    }

    useEffect(() => {
        console.log("use effect effected");
        console.log("use effect effected", spin_numbers);

        fetch_one_twenty_rows();
        last_ten_rows();
        one_twenty_hot_cold();

        fetch_data_intro();

        const centerInterval = setInterval(function () {
            var current_degree = getRotationAngle(document.getElementById("dv_spin"));

            if (
                current_degree >= 360 - onePart / 2 ||
                current_degree <= onePart / 2
            ) {
                setCenterNumber("0");
                setCenterColor("green");
                setCenterBackColor("green");
            } else {
                for (var i = 1; i < 37; i++) {
                    if (
                        parseFloat(spin_numbers[i].deg) - onePart / 2 <= current_degree &&
                        parseFloat(spin_numbers[i].deg) + onePart / 2 >= current_degree
                    ) {
                        setCenterNumber(spin_numbers[i].index);
                        setCenterColor(spin_numbers[i].color);
                        setCenterBackColor(
                            spin_numbers[i].sector === "A"
                                ? "#A15103"
                                : spin_numbers[i].sector === "B"
                                    ? "#1637E1"
                                    : spin_numbers[i].sector === "C"
                                        ? "#DB2BCA"
                                        : spin_numbers[i].sector === "D"
                                            ? "#069D69"
                                            : spin_numbers[i].sector === "E"
                                                ? "#ABA703"
                                                : spin_numbers[i].sector === "F" && "#DADFDB"
                        );
                    }
                }
            }
            clearInterval(centerInterval);
        }, 40);
    }, []);

    useEffect(() => {
        console.log('centerBackColor'+centerBackColor);
    }, [centerBackColor])
    useEffect(() => {
        console.log('centerNumber'+centerNumber);
    }, [centerNumber])
    useEffect(() => {
        console.log('centerColor'+centerColor);
    }, [centerColor])
    return (
        <div className="dv_main_container_spinner">
            <div className="dv_main_container">
                <div className="dv_internal_container_spinner">
                    <div
                        style={{ padding: "50px 20px" }}
                        className="d-flex flex-column align-content-between justify-content-between flex-wrap h-100"
                    >
                        <img height={50} src={arrow_logo} />
                        <img height={50} src={arrow_logo_bottom} />
                    </div>
                    <div></div>
                    <div style={{ marginLeft: "-10rem" }} className="dv_spinner_wheel">
                        <div className="dv_spinner_and_progress_only_spinner">
                            <div className="dv_spinner_big_container">
                                <div className="dv_spin_container">
                                    {openStatus === "closed" &&
                                        !blankCenterNumber &&
                                        !centerBigNumber ? (
                                        <div className="dv_spin_arrow">
                                            <img
                                                className="arrow"
                                                width={40}
                                                height={500}
                                                src={arrow_img}
                                            />
                                        </div>
                                    ) : (
                                        <div className="dv_spin_arrows">
                                            <img className="" width={40} height={500} src={arrow_img} />
                                        </div>
                                    )}
                                    <div
                                        className={`dv_spin ${timeRemaining >= 5000 && "hide-me"} ${centerBigNumber && "dv_big_number"
                                            }`}
                                        id="dv_spin"
                                        ref={spinRef}
                                        onTransitionEnd={onTransitionEnd}
                                        style={{
                                            transform: rotateTransform,
                                            transition: rotateTransition,
                                        }}
                                    ></div>

                                    <div className="dv_spin_center"></div>
                                    {blankCenterNumber ? (
                                        // <div
                                        //   className="dv_spin_number"
                                        //   style={{ background: "black" }}
                                        // ></div>
                                        <div className="dv_spin_number flex flex-col items-center justify-center">
                                            <p>PLACE</p>
                                            <p>BETS</p>
                                        </div>
                                    ) : centerBigNumber || openStatus === "closed" ? (
                                        <div
                                            //  className = "dv_spin_number"
                                            //   className = {centerBigNumber ? "dv_spin_number" : "dv_spin_number"}
                                            className={`${centerBigNumber ? "dv_spin_number" : "dv_spin_number"
                                                } `}
                                            //   className = {centerBigNumber ? "dv_spin_number" : "dv_spin_number_big"}
                                            style={{
                                                // borderTop: `15px solid ${centerBackColor}`,
                                                // borderBottom: `15px solid ${centerBackColor}`,
                                                // borderStyle: "15px solid none",
                                                borderStyle: "solid none solid none",
                                                borderWidth: "15px",
                                                borderColor: `${centerBackColor}`,
                                                backgroundImage:
                                                    centerNumber != ""
                                                        ? centerColor == "green"
                                                            ? "linear-gradient(45deg, green, grey)"
                                                            : centerColor == "red"
                                                                ? "linear-gradient(45deg, grey, red)"
                                                                : "linear-gradient(45deg, grey, black)"
                                                        : " ",
                                            }}
                                        >
                                            <span style={{ marginTop: "0px!important" }}>
                                                {" "}
                                                {centerNumber != "" ? centerNumber : ""}{" "}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="dv_spin_number flex flex-col items-center justify-center">
                                            <p>PLACE</p>
                                            <p>BETS</p>
                                        </div>
                                    )}
                                </div>
                                {/* <div><button onClick = {() => startSpin()}>SPIN</button></div>  */}
                            </div>
                        </div>
                    </div>
                    <div className="dv_stats_spinner">
                        <div className="position-fixed top-0 end-0">
                            <button onClick={toggleFullScreen}>
                                {isFullScreen ? (
                                    ""
                                ) : (
                                    <svg
                                        className="h-5 w-5 text-cyan-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                        <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />{" "}
                                        <path d="M4 16v2a2 2 0 0 0 2 2h2" />{" "}
                                        <path d="M16 4h2a2 2 0 0 1 2 2v2" />{" "}
                                        <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />{" "}
                                        <line x1="9" y1="12" x2="15" y2="12" />{" "}
                                        <line x1="12" y1="9" x2="12" y2="15" />
                                    </svg>
                                )}
                            </button>
                        </div>
                        <div
                            style={{ backgroundColor: "#140F07" }}
                            className="border border-3 mb-1 p-0 m-0 border-secondary rounded w-full d-flex justify-content-between align-items-center"
                        >
                            <div className="d-flex items-center w-full">
                                <h5 style={{ fontSize: "28px" }} className="crushed-regular">
                                    GAME
                                </h5>
                                <span className="px-1"></span>
                                <h5 style={{ fontSize: "28px" }} className="crushed-regular">
                                    {currentGameId}
                                </h5>
                            </div>
                            <div className="">
                                <span
                                    id="clocks"
                                    className={`clock-spinner ${timeRemaining < 10000 && "blink"}`}
                                >
                                    {timeRemaining === "BET IS CLOSED"
                                        ? "BET CLOSED"
                                        : seconds_to_clock(timeRemaining)}
                                </span>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-5 border-end border-5 border-dark m-0 mr-2 p-0 d-flex align-items-center justify-content-center"
                                    style={{ opacity: "95%" }}
                                >
                                    <div className="w-100">
                                        <div className="w-100">
                                            <span
                                                style={{ height: "38px" }}
                                                className="bg-white w-100 text-dark d-block text-center border-bottom border-dark d-flex align-items-center justify-content-center"
                                            >
                                                PAY TABLE
                                            </span>
                                            <div
                                                style={{ height: "45px" }}
                                                className="row justify-content-center overflow-hidden m-0 p-0"
                                            >
                                                <span
                                                    className="col border-bottom border-dark d-flex align-items-center justify-content-center"
                                                    style={{ backgroundColor: "#3C3528" }}
                                                >
                                                    COLOR
                                                </span>
                                                <span
                                                    className="col border-bottom border-dark d-flex align-items-center justify-content-center"
                                                    style={{ backgroundColor: "#ff0000" }}
                                                >
                                                    2
                                                </span>
                                                <span className="col border-bottom border-dark d-flex align-items-center justify-content-center bg-black">
                                                    2
                                                </span>
                                                <span
                                                    className="col border-bottom border-dark d-flex align-items-center justify-content-center"
                                                    style={{ backgroundColor: "#00B012" }}
                                                >
                                                    36
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row justify-content-center overflow-hidden m-0 p-0"
                                            style={{ height: "43px" }}
                                        >
                                            <span
                                                style={{ backgroundColor: "#FC7E0D" }}
                                                className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                            >
                                                6
                                            </span>
                                            <span
                                                style={{ backgroundColor: "blue" }}
                                                className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                            >
                                                6
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#F934F9" }}
                                                className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                            >
                                                6
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#04E499" }}
                                                className="col-2 border-bottom border-dark d-flex text-black align-items-center justify-content-center"
                                            >
                                                6
                                            </span>
                                            <span
                                                style={{ backgroundColor: "yellow" }}
                                                className="col-2 border-bottom border-dark d-flex text-black align-items-center justify-content-center"
                                            >
                                                6
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#ffffff" }}
                                                className="col-2 border-bottom border-dark d-flex text-black align-items-center justify-content-center"
                                            >
                                                6
                                            </span>
                                        </div>
                                        <div className="row p-0 m-0">
                                            <div className="row p-0 m-0" style={{ height: "43px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 opacity-100 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    NUMBER
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    36
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    DOZENS
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 opacity-100 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    3
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    ODD / EVEN
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    2
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    HIGH / LOW
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    2
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    SPLIT
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    18
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    3 LINE
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    12
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    CORNER
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    9
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark text-nowrap d-flex align-items-center justify-content-center"
                                                >
                                                    FIRST 4 CONNECTED
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    9
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    6 LINE
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    6
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    COLUMN
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    3
                                                </span>
                                            </div>
                                            <div className="row p-0 m-0" style={{ height: "45px" }}>
                                                <span
                                                    style={{ backgroundColor: "#3C3528" }}
                                                    className="col-10 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    NEIGHBOURS
                                                </span>
                                                <span
                                                    style={{ backgroundColor: "#1731B5", color: "yellow" }}
                                                    className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                                >
                                                    7
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-7 border border-dark m-0 p-0"
                                    style={{ opacity: "95%" }}
                                >
                                    <div className="w-100">
                                        <span
                                            style={{ height: "24px" }}
                                            className="bg-white w-100 text-dark d-block text-center border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            PREVIOUS 6 GAMES
                                        </span>
                                    </div>
                                    <div className="m-0 p-0">
                                        <div className="row justify-content-center overflow-hidden m-0 p-0">
                                            {/* {lastTenDrawNumbers?.slice(0, 6)?.map((item, index) => (
                        <div
                            style={{ height: "25px" }}
                            key={index}
                            className="col-6 border-top border-dark m-0 p-0"
                        >
                            <div className="row m-0 p-0">
                            <span
                                style={{
                                backgroundColor: "#3d3527",
                                }}
                                className="col-8  d-flex align-items-center justify-content-center"
                            >
                                #{item.game_number}
                            </span>
                            {spin_numbers2[item.winner_number]?.color ==
                            "black" ? (
                                <span
                                style={{ backgroundColor: "#000000" }}
                                className="col-2 d-flex align-items-center justify-content-center"
                                >
                                {item.winner_number}
                                </span>
                            ) : spin_numbers2[item.winner_number]?.color ==
                                "red" ? (
                                <span
                                style={{ backgroundColor: "#ff0000" }}
                                className="col-2 d-flex align-items-center justify-content-center"
                                >
                                {item.winner_number}
                                </span>
                            ) : (
                                <span
                                style={{ backgroundColor: "#00ff00" }}
                                className="col-2 d-flex align-items-center justify-content-center"
                                >
                                {item.winner_number}
                                </span>
                            )}
                            {spin_numbers2[item.winner_number]?.sector == "A" ? (
                                <span
                                style={{ backgroundColor: "#FE811A" }}
                                className="col-2  d-flex align-items-center justify-content-center"
                                ></span>
                            ) : spin_numbers2[item.winner_number]?.sector ==
                                "B" ? (
                                <span
                                style={{ backgroundColor: "#0000ff" }}
                                className="col-2  d-flex align-items-center justify-content-center"
                                ></span>
                            ) : spin_numbers2[item.winner_number]?.sector ==
                                "C" ? (
                                <span
                                style={{ backgroundColor: "#F635F5" }}
                                className="col-2  d-flex align-items-center justify-content-center"
                                ></span>
                            ) : spin_numbers2[item.winner_number]?.sector ==
                                "D" ? (
                                <span
                                style={{ backgroundColor: "#02E59C" }}
                                className="col-2  d-flex align-items-center justify-content-center"
                                ></span>
                            ) : spin_numbers2[item.winner_number]?.sector ==
                                "E" ? (
                                <span
                                style={{ backgroundColor: "#FAFE04" }}
                                className="col-2  d-flex align-items-center justify-content-center"
                                ></span>
                            ) : (
                                spin_numbers2[item.winner_number]?.sector ==
                                "F" && (
                                <span
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    className="col-2  d-flex align-items-center justify-content-center"
                                ></span>
                                )
                            )}
                            <span
                                style={{ backgroundColor: "#0000ff" }}
                                className="col-2  d-flex align-items-center justify-content-center"
                            ></span>
                            </div>
                        </div>
                        ))} */}
                                            <div
                                                style={{ height: "25px" }}
                                                className="col-6 border-top border-dark m-0 p-0"
                                            >
                                                <div className="row m-0 p-0">
                                                    <span
                                                        style={{
                                                            backgroundColor: "#3d3527",
                                                        }}
                                                        className="col-8  d-flex align-items-center justify-content-center"
                                                    >
                                                        #{lastTenDrawNumbers[0]?.game_number}
                                                    </span>
                                                    {spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.color == "black" ? (
                                                        <span
                                                            style={{ backgroundColor: "#000000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[0]?.winner_number}
                                                        </span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.color == "red" ? (
                                                        <span
                                                            style={{ backgroundColor: "#ff0000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[0]?.winner_number}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ backgroundColor: "#00ff00" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[0]?.winner_number}
                                                        </span>
                                                    )}
                                                    {spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.sector == "A" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FE811A" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.sector == "B" ? (
                                                        <span
                                                            style={{ backgroundColor: "#0000ff" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.sector == "C" ? (
                                                        <span
                                                            style={{ backgroundColor: "#F635F5" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.sector == "D" ? (
                                                        <span
                                                            style={{ backgroundColor: "#02E59C" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                        ?.sector == "E" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FAFE04" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : (
                                                        spin_numbers2[lastTenDrawNumbers[0]?.winner_number]
                                                            ?.sector == "F" && (
                                                            <span
                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                className="col-2  d-flex align-items-center justify-content-center"
                                                            ></span>
                                                        )
                                                    )}
                                                    <span
                                                        style={{ backgroundColor: "#0000ff" }}
                                                        className="col-2  d-flex align-items-center justify-content-center"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: "25px" }}
                                                className="col-6 border-top border-dark m-0 p-0"
                                            >
                                                <div className="row m-0 p-0">
                                                    <span
                                                        style={{
                                                            backgroundColor: "#3d3527",
                                                        }}
                                                        className="col-8  d-flex align-items-center justify-content-center"
                                                    >
                                                        #{lastTenDrawNumbers[3]?.game_number}
                                                    </span>
                                                    {spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.color == "black" ? (
                                                        <span
                                                            style={{ backgroundColor: "#000000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[3]?.winner_number}
                                                        </span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.color == "red" ? (
                                                        <span
                                                            style={{ backgroundColor: "#ff0000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[3]?.winner_number}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ backgroundColor: "#00ff00" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[3]?.winner_number}
                                                        </span>
                                                    )}
                                                    {spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.sector == "A" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FE811A" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.sector == "B" ? (
                                                        <span
                                                            style={{ backgroundColor: "#0000ff" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.sector == "C" ? (
                                                        <span
                                                            style={{ backgroundColor: "#F635F5" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.sector == "D" ? (
                                                        <span
                                                            style={{ backgroundColor: "#02E59C" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                        ?.sector == "E" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FAFE04" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : (
                                                        spin_numbers2[lastTenDrawNumbers[3]?.winner_number]
                                                            ?.sector == "F" && (
                                                            <span
                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                className="col-2  d-flex align-items-center justify-content-center"
                                                            ></span>
                                                        )
                                                    )}
                                                    <span
                                                        style={{ backgroundColor: "#0000ff" }}
                                                        className="col-2  d-flex align-items-center justify-content-center"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: "25px" }}
                                                className="col-6 border-top border-dark m-0 p-0"
                                            >
                                                <div className="row m-0 p-0">
                                                    <span
                                                        style={{
                                                            backgroundColor: "#3d3527",
                                                        }}
                                                        className="col-8  d-flex align-items-center justify-content-center"
                                                    >
                                                        #{lastTenDrawNumbers[1]?.game_number}
                                                    </span>
                                                    {spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.color == "black" ? (
                                                        <span
                                                            style={{ backgroundColor: "#000000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[1]?.winner_number}
                                                        </span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.color == "red" ? (
                                                        <span
                                                            style={{ backgroundColor: "#ff0000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[1]?.winner_number}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ backgroundColor: "#00ff00" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[1]?.winner_number}
                                                        </span>
                                                    )}
                                                    {spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.sector == "A" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FE811A" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.sector == "B" ? (
                                                        <span
                                                            style={{ backgroundColor: "#0000ff" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.sector == "C" ? (
                                                        <span
                                                            style={{ backgroundColor: "#F635F5" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.sector == "D" ? (
                                                        <span
                                                            style={{ backgroundColor: "#02E59C" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                        ?.sector == "E" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FAFE04" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : (
                                                        spin_numbers2[lastTenDrawNumbers[1]?.winner_number]
                                                            ?.sector == "F" && (
                                                            <span
                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                className="col-2  d-flex align-items-center justify-content-center"
                                                            ></span>
                                                        )
                                                    )}
                                                    <span
                                                        style={{ backgroundColor: "#0000ff" }}
                                                        className="col-2  d-flex align-items-center justify-content-center"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: "25px" }}
                                                className="col-6 border-top border-dark m-0 p-0"
                                            >
                                                <div className="row m-0 p-0">
                                                    <span
                                                        style={{
                                                            backgroundColor: "#3d3527",
                                                        }}
                                                        className="col-8  d-flex align-items-center justify-content-center"
                                                    >
                                                        #{lastTenDrawNumbers[4]?.game_number}
                                                    </span>
                                                    {spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.color == "black" ? (
                                                        <span
                                                            style={{ backgroundColor: "#000000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[4]?.winner_number}
                                                        </span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.color == "red" ? (
                                                        <span
                                                            style={{ backgroundColor: "#ff0000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[4]?.winner_number}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ backgroundColor: "#00ff00" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[4]?.winner_number}
                                                        </span>
                                                    )}
                                                    {spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.sector == "A" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FE811A" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.sector == "B" ? (
                                                        <span
                                                            style={{ backgroundColor: "#0000ff" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.sector == "C" ? (
                                                        <span
                                                            style={{ backgroundColor: "#F635F5" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.sector == "D" ? (
                                                        <span
                                                            style={{ backgroundColor: "#02E59C" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                        ?.sector == "E" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FAFE04" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : (
                                                        spin_numbers2[lastTenDrawNumbers[4]?.winner_number]
                                                            ?.sector == "F" && (
                                                            <span
                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                className="col-2  d-flex align-items-center justify-content-center"
                                                            ></span>
                                                        )
                                                    )}
                                                    <span
                                                        style={{ backgroundColor: "#0000ff" }}
                                                        className="col-2  d-flex align-items-center justify-content-center"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: "25px" }}
                                                className="col-6 border-top border-dark m-0 p-0"
                                            >
                                                <div className="row m-0 p-0">
                                                    <span
                                                        style={{
                                                            backgroundColor: "#3d3527",
                                                        }}
                                                        className="col-8  d-flex align-items-center justify-content-center"
                                                    >
                                                        #{lastTenDrawNumbers[2]?.game_number}
                                                    </span>
                                                    {spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.color == "black" ? (
                                                        <span
                                                            style={{ backgroundColor: "#000000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[2]?.winner_number}
                                                        </span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.color == "red" ? (
                                                        <span
                                                            style={{ backgroundColor: "#ff0000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[2]?.winner_number}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ backgroundColor: "#00ff00" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[2]?.winner_number}
                                                        </span>
                                                    )}
                                                    {spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.sector == "A" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FE811A" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.sector == "B" ? (
                                                        <span
                                                            style={{ backgroundColor: "#0000ff" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.sector == "C" ? (
                                                        <span
                                                            style={{ backgroundColor: "#F635F5" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.sector == "D" ? (
                                                        <span
                                                            style={{ backgroundColor: "#02E59C" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                        ?.sector == "E" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FAFE04" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : (
                                                        spin_numbers2[lastTenDrawNumbers[2]?.winner_number]
                                                            ?.sector == "F" && (
                                                            <span
                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                className="col-2  d-flex align-items-center justify-content-center"
                                                            ></span>
                                                        )
                                                    )}
                                                    <span
                                                        style={{ backgroundColor: "#0000ff" }}
                                                        className="col-2  d-flex align-items-center justify-content-center"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: "25px" }}
                                                className="col-6 border-top border-dark m-0 p-0"
                                            >
                                                <div className="row m-0 p-0">
                                                    <span
                                                        style={{
                                                            backgroundColor: "#3d3527",
                                                        }}
                                                        className="col-8  d-flex align-items-center justify-content-center"
                                                    >
                                                        #{lastTenDrawNumbers[5]?.game_number}
                                                    </span>
                                                    {spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.color == "black" ? (
                                                        <span
                                                            style={{ backgroundColor: "#000000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[5]?.winner_number}
                                                        </span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.color == "red" ? (
                                                        <span
                                                            style={{ backgroundColor: "#ff0000" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[5]?.winner_number}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ backgroundColor: "#00ff00" }}
                                                            className="col-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            {lastTenDrawNumbers[5]?.winner_number}
                                                        </span>
                                                    )}
                                                    {spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.sector == "A" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FE811A" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.sector == "B" ? (
                                                        <span
                                                            style={{ backgroundColor: "#0000ff" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.sector == "C" ? (
                                                        <span
                                                            style={{ backgroundColor: "#F635F5" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.sector == "D" ? (
                                                        <span
                                                            style={{ backgroundColor: "#02E59C" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                        ?.sector == "E" ? (
                                                        <span
                                                            style={{ backgroundColor: "#FAFE04" }}
                                                            className="col-2  d-flex align-items-center justify-content-center"
                                                        ></span>
                                                    ) : (
                                                        spin_numbers2[lastTenDrawNumbers[5]?.winner_number]
                                                            ?.sector == "F" && (
                                                            <span
                                                                style={{ backgroundColor: "#FFFFFF" }}
                                                                className="col-2  d-flex align-items-center justify-content-center"
                                                            ></span>
                                                        )
                                                    )}
                                                    <span
                                                        style={{ backgroundColor: "#0000ff" }}
                                                        className="col-2  d-flex align-items-center justify-content-center"
                                                    ></span>
                                                </div>
                                            </div>

                                            {/*  */}
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <span
                                            style={{ height: "24px" }}
                                            className="bg-white w-100 border-top-4 text-dark d-block text-center border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            FREQUENCY( LAST 200 DRAWS)
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <div className="row justify-content-center overflow-hidden m-0 p-0">
                                            {spin_numbers_array.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="col-4 border border-dark"
                                                    style={{ height: "25px" }}
                                                >
                                                    <div className="row">
                                                        <span
                                                            className="col-6 d-flex align-items-center text-xl justify-content-center"
                                                            style={{
                                                                background:
                                                                    spin_numbers2[item].color == "red"
                                                                        ? "#D20905"
                                                                        : "#070D02",
                                                            }}
                                                        >
                                                            {item}
                                                        </span>
                                                        <span
                                                            style={{ backgroundColor: "#1637E2" }}
                                                            className="col-6 d-flex align-items-center justify-content-center"
                                                        >
                                                            {oneTwentyArray[item]}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <span
                                            style={{ height: "26px" }}
                                            className="bg-white w-full border-top border-3 text-dark d-block text-center border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            COLORS ( LAST 200 DRAWS)
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <div className="row justify-content-center overflow-hidden m-0 p-0">
                                            <span
                                                className="col d-flex align-items-center justify-content-center"
                                                style={{ background: "red" }}
                                            >
                                                {oneTwentyColors[0]}
                                            </span>
                                            <span
                                                className="col d-flex align-items-center justify-content-center"
                                                style={{ background: "black" }}
                                            >
                                                {oneTwentyColors[1]}
                                            </span>
                                            <span
                                                className="col d-flex align-items-center justify-content-center"
                                                style={{ background: "green" }}
                                            >
                                                {oneTwentyColors[2]}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center overflow-hidden m-0 p-0">
                                        <span
                                            style={{ backgroundColor: "#FC7E0D" }}
                                            className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            {oneTwentySectors[0]}
                                        </span>
                                        <span
                                            style={{ backgroundColor: "blue" }}
                                            className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            {oneTwentySectors[1]}
                                        </span>
                                        <span
                                            style={{ backgroundColor: "#F934F9" }}
                                            className="col-2 border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            {oneTwentySectors[2]}
                                        </span>
                                        <span
                                            style={{ backgroundColor: "#04E499" }}
                                            className="col-2 border-bottom border-dark d-flex text-black align-items-center justify-content-center"
                                        >
                                            {oneTwentySectors[3]}
                                        </span>
                                        <span
                                            style={{ backgroundColor: "yellow" }}
                                            className="col-2 border-bottom border-dark d-flex text-black align-items-center justify-content-center"
                                        >
                                            {oneTwentySectors[4]}
                                        </span>
                                        <span
                                            style={{ backgroundColor: "#ffffff" }}
                                            className="col-2 border-bottom border-dark d-flex text-black align-items-center justify-content-center"
                                        >
                                            {oneTwentySectors[5]}
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <span
                                            style={{ height: "24px" }}
                                            className="bg-white w-100 border-top border-3 text-dark d-block text-center border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            DOZENS ( LAST 200 DRAWS)
                                        </span>
                                    </div>
                                    <div style={{ fontSize: "15px" }} className="w-100">
                                        <div className="row justify-content-center overflow-hidden m-0 p-0">
                                            <span
                                                style={{ backgroundColor: "#272722" }}
                                                className="col d-flex text-nowrap align-items-center justify-content-center"
                                            >
                                                1-12
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#0000ff" }}
                                                className="col d-flex  align-items-center justify-content-center"
                                            >
                                                {dozens[0]}
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#272722" }}
                                                className="col d-flex text-nowrap align-items-center justify-content-center"
                                            >
                                                13-24
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#0000ff" }}
                                                className="col d-flex align-items-center justify-content-center"
                                            >
                                                {dozens[1]}
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#272722" }}
                                                className="col text-nowrap d-flex align-items-center justify-content-center"
                                            >
                                                25-36
                                            </span>
                                            <span
                                                style={{ backgroundColor: "#0000ff" }}
                                                className="col d-flex align-items-center justify-content-center"
                                            >
                                                {dozens[2]}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <span
                                            style={{ height: "24px" }}
                                            className="bg-white w-100 border-top border-3 text-dark d-block text-center border-bottom border-dark d-flex align-items-center justify-content-center"
                                        >
                                            HOT/COLD ( LAST 200 DRAWS)
                                        </span>
                                    </div>
                                    <div className="w-100">
                                        <div className="row justify-content-center overflow-hidden m-0 p-0">
                                            <div className="col-2 d-flex align-items-center justify-content-center m-0 p-0">
                                                <span
                                                    style={{ backgroundColor: "#FA4B01", height: "25px" }}
                                                    className="col d-flex align-items-center justify-content-center"
                                                >
                                                    HOT
                                                </span>
                                            </div>
                                            <div className="col-10 d-flex align-items-center p-0 m-0">
                                                <div
                                                    style={{ width: "100%" }}
                                                    className="row m-0 p-0 justify-content-center overflow-hidden"
                                                >
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[36]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[36]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[36]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[35]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[35]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[35]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[34]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[34]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[34]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[33]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[33]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[33]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[32]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[32]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[32]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[31]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[31]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[31]}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center overflow-hidden m-0 p-0">
                                            <div className="col-2 d-flex align-items-center justify-content-center m-0 p-0">
                                                <span
                                                    // style={{ backgroundColor: "#1675EE" }}
                                                    style={{ backgroundColor: "#1675EE", height: "25px" }}
                                                    className="col d-flex align-items-center justify-content-center"
                                                >
                                                    COLD
                                                </span>
                                            </div>
                                            <div className="col-10 w-full d-flex align-items-center justify-content-center m-0 p-0">
                                                <div
                                                    style={{ width: "100%" }}
                                                    className="row w-full overflow-hidden"
                                                >
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[5]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[5]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[5]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[4]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[4]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[4]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[3]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[3]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[3]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark w-full d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[2]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[2]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[2]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[1]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[1]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[1]}
                                                    </span>
                                                    <span
                                                        className="col-2 border border-dark d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "25px",
                                                            background:
                                                                spin_numbers2[oneTwentyHotColdNums[0]]?.color ==
                                                                    "red"
                                                                    ? "red"
                                                                    : spin_numbers2[oneTwentyHotColdNums[0]]?.color == "green"
                                                                        ? "green"
                                                                        : "black",
                                                        }}
                                                    >
                                                        {oneTwentyHotColdNums[0]}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <video
            src={videoBg}
            autoPlay
            loop
            muted
            style={{ opacity: centerBigNumber ? "0.4" : "0" }}
            id="vid_bg"
        /> */}
                <audio
                    ref={audioRef}
                    src={audioPath}
                    autoPlay
                    muted={!hasUserInteracted}
                />
                {/* <button onClick={playAudio}>Play Audio</button> */}
                {/* <AudioPlayer audioSrc="../resources/mixkit-antique-movie-projector-spinning-1444.wav" /> */}
            </div>
        </div>
    );
};

export default TheGame;
