import React, { useState } from "react";
import '../../../resources/Keno/css/quick.css';

const QuickPickButton = ({ onSelect }) => {
  // State to store the selected number
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Function to handle when a number is clicked
  const handleNumberClick = (number) => {
    setSelectedNumber(number);
    onSelect(number); // Call the onSelect prop with the selected number
    setDropdownOpen(false); // Close the dropdown after selecting a number
  };

  return (
    <div className="quick-pick-container">
      <div className={`dropdown ${dropdownOpen ? "open" : ""}`}>
        <button
          className="quick-pick-btn"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          QUICK PICK
          <select
            type="text"
            placeholder=""
            className="small-input" // Add a class for smaller input
            value={selectedNumber !== null ? selectedNumber : ""} // Set value of text field to selected number
            onClick={() => setDropdownOpen(true)} // Open dropdown when input is clicked
            readOnly // Make the input field read-only
          />
          {dropdownOpen && (
            <div className="dropdown-content">
              {[...Array(10)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleNumberClick(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default QuickPickButton;
