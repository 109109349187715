import React, { useState } from 'react';
import "../../../resources/Spinner/css/cancel_button.css";

function CancelButton({ handleNumber, handleClear,handleEnter }) {
 

  return (
    <div className="calculator">
     
      <div className="buttons">
      <button onClick={() => handleNumber(1)}>1</button>
        <button onClick={() => handleNumber(2)}>2</button>
        <button onClick={() => handleNumber(3)}>3</button>
        <button onClick={() => handleNumber(4)}>4</button>
        <button onClick={() => handleNumber(5)}>5</button>
        <button onClick={() => handleNumber(6)}>6</button>
        <button onClick={() => handleNumber(7)}>7</button>
        <button onClick={() => handleNumber(8)}>8</button>
        <button onClick={() => handleNumber(9)}>9</button>     
      
        <button onClick={() => handleNumber(0)}>0</button>

     
      </div>
      <div className="bottom_buttons">
       
        
        <button onClick={handleClear}>Clear</button>
        <button onClick={handleEnter}>Enter</button>

      </div>
    </div>
  );
}

export default CancelButton;