import React, { useState } from "react";
import "../../../resources/Spinner/css/pagination.css"; // Import CSS file for styling



const Pagination = ({ currentPage, totalPages, onPageChange }) => {

  const handleLeftClick = () => {
    if (currentPage > 0 ) {
      onPageChange(currentPage - 1,false);
    }
  };

  const handleRightClick = () => {
    if(currentPage == 0 ){
      onPageChange( 21,false);
    }else if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1,false);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page,true);
  };

  const renderPagination = () => {
    const renderPages = [];
    const start = Math.max(0, currentPage - 2);

    // Calculate the start and end numbers for pagination
    let startNum = 0;
    let endNum = totalPages;
    if (totalPages > 21) {
      if (currentPage >= totalPages - 10) {
        startNum = totalPages - 20;
        endNum = totalPages;
      } else if (currentPage <= 10) {
        startNum = 0;
        endNum = 22;
      } else {
        startNum = currentPage - 10;
        endNum = currentPage + 10;
      }
    }

    for (let i = startNum; i < endNum; i++) {
      renderPages.push(
        <li
          key={i}
          // className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <a
            onClick={() => handlePageClick(i)}
            href="#!"
            className={`page-link ${
              i === 0 ? "green" :i === 11 ? "black" : i === 12 ? "red" : i === 13 ? "black": i === 14 ? "red" : i === 15 ? "black" : i === 16 ? "red" : i === 17 ? "black"  : i === 18 ? "red" : i === 29 ? "black" : i === 30 ? "red" : i === 31 ? "black" : i === 32 ? "red" : i === 33 ? "black"  : i === 34 ? "red" : i === 35 ? "black" : i === 36 ? "red": i % 2 === 0 ? "black" : "red"
            }`}
          >
            {i}
          </a>
        </li>
      );
    }
    return renderPages;
  };

  return (
    <div className="pagination-container">
      <ul className="pagination">
        <li>
          <button onClick={handleLeftClick} className="left">
            &lsaquo; {/* Unicode for single left arrow */}
          </button>
        </li>
        {renderPagination()}
        <li>
          <button onClick={handleRightClick} className=" right">
            &rsaquo; {/* Unicode for single right arrow */}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
