import { useState} from 'react';
 import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';  //npm install react-router-dom@5
 import TheGame from './components/Spinner/TheGame';
 import Cashier from './components/Spinner/Cashier';
 import CashierLogin from './components/Spinner/CashierLogin';
 import AdminPage from './components/Spinner/AdminPage';
 import AdminDashboard from './components/Spinner/AdminDashboard';
 import AdminShift from './components/Spinner/AdminShift';
 import AdminGameStat from './components/Spinner/AdminGameStat';
 import AdminPeople from './components/Spinner/AdminPeople';
 import AdminSettings from './components/Spinner/AdminSettings';
 import AdminLogin from './components/Spinner/AdminLogin';
 import AdminTickets from './components/Spinner/AdminTickets';
 import PartnerDashboard from './components/Spinner/PartnerDashboard';
 import PartnerShift from './components/Spinner/PartnerShift';
 import PartnerGameStat from './components/Spinner/PartnerGameStat';
 import PartnerLogin from './components/Spinner/PartnerLogin';
 import PartnerTickets from './components/Spinner//PartnerTickets';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function SpinnerAppRoute() {

  return (
    <>
        <Route
        path="/spinner/monitor"
        component = {TheGame}
        />
        <Route
        path="/spinner/cashier"
        component = {sessionStorage.getItem('cashier_name') != null ? Cashier : CashierLogin}
        />
        <Route
        path="/spinner/admin"
        component = {sessionStorage.getItem('admin_name') != null ? AdminDashboard : AdminLogin}
        />
        <Route
        path="/spinner/adminshift"
        component = {sessionStorage.getItem('admin_name') != null ? AdminShift : AdminLogin}
        />
        <Route
        path="/spinner/admingamestat"
        component = {sessionStorage.getItem('admin_name') != null ? AdminGameStat : AdminLogin }
        />
        <Route
        path="/spinner/adminpeople"
        component = {sessionStorage.getItem('admin_name') != null ? AdminPeople : AdminLogin }
        />
        <Route
        path="/spinner/adminsettings"
        component = {sessionStorage.getItem('admin_name') != null ? AdminSettings : AdminLogin }
        />
        <Route
        path="/spinner/admintickets"
        component = {sessionStorage.getItem('admin_name') != null ? AdminTickets : AdminLogin }
        />
        <Route
        path="/spinner/partner"
        component = {sessionStorage.getItem('partner_name') != null ? PartnerDashboard : PartnerLogin}
        />
        
        <Route
        path="/spinner/partnershift"
        component = {sessionStorage.getItem('partner_name') != null ? PartnerShift : PartnerLogin}
        />
        <Route
        path="/spinner/partnergamestat"
        component = {sessionStorage.getItem('partner_name') != null ? PartnerGameStat : PartnerLogin }
        />
        <Route
        path="/spinner/partnertickets"
        component = {sessionStorage.getItem('partner_name') != null ? PartnerTickets : PartnerLogin }
        />
    </>
   
    
  );
}

export default SpinnerAppRoute;
