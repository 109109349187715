import React, { useState, useEffect, forwardRef, useRef} from 'react';
import { link } from 'react-router-dom';

import { FaCheck, FaSignOutAlt, FaPrint, FaBars, FaCalendar , FaCogs, FaRegUser, FaTicketAlt,FaRegUserCircle, FaTimes, FaChartBar, FaFlipboard } from 'react-icons/fa';
const roulette_logo = require('../../../resources/Keno/img/roulettelogo.png');
const site_logo = require('../../../resources/Keno/img/roulettelogo.png');

const NavbarPartner = ({currentPage }) => {

   const navRef = useRef();
   const sign_out = () => {
    sessionStorage.removeItem('partner_name');
    
    
   // console.log("signed out");
   window.location.replace("/partner");
  }

   const showNavbar = () => {
       navRef.current.classList.toggle("admin_responsive_nav") ;
   }
    
   const display = () => {
       console.log("hello");
   }
    return (
    <header className = "admin_header">
            <h3 style = {{marginBottom: "-3px", marginLeft: "1rem", cursor: "pointer"}}
            onClick = {(e) => {
                window.location.replace("/partner");
            }}
            ><img src={site_logo} className = "img_site_logo" style = {{width: "40px", height: "40px",  marginBottom: "3px"}}/></h3>
            <nav ref = {navRef} className = "admin_nav">
           <a href = "/partner" 
            
           ><span style = {{color: currentPage == 'partnerdashboard' && '#999'}}><FaFlipboard style = {{marginBottom: "3px"}} />&nbsp;Dashboard</span></a>
                
                <a  href = "/partnershift" 
                
                ><span style = {{color: currentPage == 'partnershift' && '#999'}}><FaCalendar style = {{marginBottom: "3px"}} />&nbsp;Shift</span></a>
                <a href = "/partnergamestat"
                
                ><span style = {{color: currentPage == 'partnergamestat' && '#999'}}><FaChartBar style = {{marginBottom: "3px"}} />&nbsp;Game Stat</span></a>
                
                <a href = "/partnertickets"
                
                ><span style = {{color: currentPage == 'partnertickets' && '#999'}}><FaTicketAlt style = {{marginBottom: "3px"}} />&nbsp;Tickets</span></a>
                
                <a href = "#" className = "admin_logout_small_screen"
                  onClick = {(e) => {
                    e.preventDefault();
                    sign_out();
                }}
                ><FaSignOutAlt />&nbsp;Log out</a>
                {/* <span className = "cashier_spn_signout" onClick = {() => sign_out()}><FaSignOutAlt /> Sign Out</span> */}
                <button className = "admin_nav-btn admin_nav-close-btn" onClick = {showNavbar} >
                        <FaTimes />
                </button>
                
            </nav>
            <a href = "#" className = "admin_logout_big_screen"
            onClick = {(e) => {
                e.preventDefault();
                sign_out();
            }}
            ><FaSignOutAlt />&nbsp;Log out</a>
            <button className = "admin_nav-btn" onClick = {showNavbar} >
                <FaBars />
            </button>

    </header>
    
    ); 

}
export default NavbarPartner;