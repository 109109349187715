import React, { useState, useEffect, forwardRef, useRef} from 'react';
import '../../../resources/Keno/css/admin.css';
import { FaCheck, FaSignOutAlt, FaPrint, FaTimes, FaSearch, FaIconDashboard, FaExclamationCircle, FaShoppingCart, FaCoins } from 'react-icons/fa';


//import Navbar from '../navbars/Navbar';
const calendar_logo = require('../../../resources/Keno/img/icons-calendar.png');
const payIcon = require('../../../resources/Keno/img/pay.png');

const { base_url } = require(".././../../constants/index.js")



const Shift = () => {

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isError, setIsError] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [initialIndex, setInitialIndex] = useState(0);
    const [finalIndex, setFinalIndex] = useState(9);
    const [pageNum, setPageNum] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    
  

const stat_report_by_day_cashier = async (from, to ) => {
  const x = new Date(from);
  const y = new Date(to);
  if(x <= y && from != "" && to != ""){
    setIsError(false);
    const res = await fetch(`${base_url}all_tables/users/statreportbydaycashier`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({from_date: from, to_date: to})
    }).then(response => {
      if (response.ok) {
        response.json().then(json => {
          
            // setTm(json);
             console.log(json);
             setFetchedData(json);
        
        });
      } else {
        setIsError(true);
      }
    }).catch((err) => {
      setIsError(true);
          console.log("connection not working") ;
    });
  }
  
  else{
    setIsError(true);
  }
}
useEffect(  () => {
  const d = new Date();
  const prev_d = new Date();
 // d.setDate(d.getDate() + 1);
  const d_str = d.toISOString().replace('-', '/').split('T')[0].replace('-', '/');
  prev_d.setDate(prev_d.getDate() - 6);
  const prev_d_str = prev_d.toISOString().replace('-', '/').split('T')[0].replace('-', '/');
 // let text = d.toString();

 setFromDate(prev_d.toISOString().substring(0,10));
 setToDate(d.toISOString().substring(0,10));
 
// stat_report_by_day_cashier(prev_d_str, d_str);
 stat_report_by_day_cashier(prev_d.toISOString().substring(0,10), d.toISOString().substring(0,10));
 
  //console.log(next_d_str.split("/")[1]+"/"+next_d_str.split("/")[2]+"/"+next_d_str.split("/")[0]);
 
},[]);
   
    return (
    //   <div className = "admin_container" style = {{ background: "#D3D3D3", minHeight: "100vh" }}>
    // <React.Fragment>
    //     <Navbar handlePage = {change_page} />
    // </React.Fragment>
    <div>
        <div style = {{height: "150px", background: "#86A879", padding: "1.2rem 2rem"}}>  
        <span style = {{color: "#fff"}}><span style = {{color: "#dcdcdc"}}>Admin</span> / Shift</span><br></br>
        <span style = {{color: "#fff"}}>Stats</span>
        </div>
        
        

    {/* <div className = "row" style = {{ color: "#fff"}}>
      <div className = "col-lg-6">
        fdgdgdf
      </div>
      <div className = "col-lg-6">
        fdgdgdf
      </div>
      </div> */}
      <div className = "admin_rep" style = {{background: "#fff",  borderRadius: "10px", marginTop: "-40px" , marginBottom: "-160px"}}>
          <div className = "admin_date_range">
          <div  ><label className = "admin_rep_date_lbl">Select Report Date: </label></div>
          <div > <div style = {{position: "relative", display: "inline-block", zIndex: "1"}}>
            <input type="date" className = "admin_input_date" 
            value = {fromDate}
            onChange = {(e) => setFromDate(e.target.value)}
            />
          <label className = "admin_lbl">From</label></div> </div>
          <div > <div style = {{position: "relative", display: "inline-block"}}>
            <input type="date" className = "admin_input_date" 
            value = {toDate}
            onChange = {(e) => setToDate(e.target.value)}
            />
          <label className = "admin_lbl">To</label></div> </div>
          <div><button 
          onClick = {() => stat_report_by_day_cashier(fromDate, toDate)}
          ><FaSearch />&nbsp;Search</button></div>
          </div>
          <div className = "admin_report_tbl">
           {
            isError ?  <div style = {{color: "#666", fontSize: "18px", textAlign: "center", marginTop: "20px"}}>
              
              <FaExclamationCircle style= {{marginBottom: "5px"}} />&nbsp;There is an error in the date specified</div> : 
            <div>
              
                {/* { fetchedData.bet_money_total.map((item, i) => {
                  return <span>{fetchedData.bet_money_total[0]}</span>
                }) }  */}
                {/* <span>{fetchedData.bet_money_total.length}</span> */}
                <div className="table-responsive" style = {{marginTop: "20px"}}>
                      <table className="table admin_tbl_report">
                      <thead >
    <tr >
      <th  scope="col" >Date</th>
      <th scope = "col">Full Name</th>
      <th  scope="col">Total Money</th>
      <th  scope="col">Winner's Money</th>
      <th  scope="col">Total Earning</th>
    </tr>
  </thead>
  <tbody >

    {fetchedData.stat_date && fetchedData.stat_date.map((item, index) => {
      let i = (fetchedData.stat_date.length - 1) - index;
      return <tr key = {i}>
        <td style = {{display : "flex", alignItems: "center", justifyContent: "flex-start"}}>
        <img src={calendar_logo} className = "img_site_logo" style = {{width: "30px", height: "30px", marginRight: "30px"}}/>
        <span><span style = {{fontWeight: "600"}}>{fetchedData.stat_day[i]} </span>
        <br></br>
        <span style = {{fontSize: "14px", color: "#666"}}>{fetchedData.stat_date[i]}</span></span>
        </td>
        <td >
        
          <span>
          <span>{fetchedData.stat_cashier[i]}</span>
       
        </span>
        </td>
        <td >
        
          <span>
          <span>{fetchedData.stat_total_collected[i]}</span>
        <br></br>
        <span style = {{fontSize: "14px", color: "#666"}}>Birr</span>
        </span>
        </td>
      <td>
        <>
        {fetchedData.stat_total_paid[i]}
      {/* &nbsp;({40}) */}
      {/* {2/4} */}
       &nbsp;({ fetchedData.stat_total_collected[i] > 0 ? Math.round((fetchedData.stat_total_paid[i] * 100 )/ fetchedData.stat_total_collected[i]) : 0
}%)
<br></br>
<span style = {{fontSize: "14px", color: "#666"}}>Birr</span>
</>
      </td>
      <td>
        <>
        {
        fetchedData.stat_total_collected[i] - fetchedData.stat_total_paid[i]
      }
      &nbsp;({
      fetchedData.stat_total_collected[i] > 0 ? Math.round(((fetchedData.stat_total_collected[i] - fetchedData.stat_total_paid[i]) * 100)/fetchedData.stat_total_collected[i]) : 0
  
        }%)
        <br></br>
          <span style = {{fontSize: "14px", color: "#666"}}>Birr</span>
        </>
        </td></tr>
    })}
  </tbody>
                      </table>
                </div> 
                
              
            </div>
           }
          </div>
          
      </div>
    </div>
    ); 

}
export default Shift;