import React, { useState, useEffect, forwardRef, useRef } from "react";
import "../../resources/Spinner/css/admin.css";

import Navbar from "./navbars/NavbarPartner";
import Dashboard from "./partner_components/Dashboard";
import Shift from "./partner_components/Shift";
import GameStat from "./partner_components/GameStat";

import Tickets from "./partner_components/Tickets";

const { base_url } = require("../../constants/index.js")

const PartnerDashboard = () => {
  const [currentPage, setCurrentPage] = useState("dashboard");

  return (
    <div
      className="admin_container"
      style={{ background: "#D3D3D3", minHeight: "100vh" }}
    >
      <React.Fragment>
        <Navbar currentPage="partnerdashboard" />
      </React.Fragment>
      <Dashboard />
    </div>
  );
};
export default PartnerDashboard;
