import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; //npm install react-router-dom@5
import TheGame from "./components/Keno/TheGame";
import Cashier from "./components/Keno/Cashier";
import CashierLogin from "./components/Keno/CashierLogin";
import AdminPage from "./components/Keno/AdminPage";
import AdminDashboard from "./components/Keno/AdminDashboard";
import AdminShift from "./components/Keno/AdminShift";
import AdminGameStat from "./components/Keno/AdminGameStat";
import AdminPeople from "./components/Keno/AdminPeople";
import AdminSettings from "./components/Keno/AdminSettings";
import AdminSpinnerSettings from "./components/Keno/SpinnerAdminSettings";
import AdminLogin from "./components/Keno/AdminLogin";
import AdminTickets from "./components/Keno/AdminTickets";
import PartnerDashboard from "./components/Keno/PartnerDashboard";
import PartnerShift from "./components/Keno/PartnerShift";
import PartnerGameStat from "./components/Keno/PartnerGameStat";
import PartnerLogin from "./components/Keno/PartnerLogin";
import PartnerTickets from "./components/Keno/PartnerTickets";
import SpinnerAppRoute from "./SpinnerRoute";
//https://tilahunback.goldengames39.coom
// https://tilahunback.goldengames39.coom/
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/monitor" component={TheGame} />
          <Route
            path="/cashier"
            component={
              sessionStorage.getItem("cashier_name") != null
                ? Cashier
                : CashierLogin
            }
          />
          {/* <Route path ="/admin" component = {AdminPage} /> */}
          <Route
            path="/admin"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminDashboard
                : AdminLogin
            }
          />
          <Route
            path="/adminshift"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminShift
                : AdminLogin
            }
          />
          <Route
            path="/admingamestat"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminGameStat
                : AdminLogin
            }
          />
          <Route
            path="/adminpeople"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminPeople
                : AdminLogin
            }
          />
          <Route
            path="/adminsettings"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminSettings
                : AdminLogin
            }
          />
          <Route
            path="/adminspinnersettings"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminSpinnerSettings
                : AdminLogin
            }
          />
          <Route
            path="/admintickets"
            component={
              sessionStorage.getItem("admin_name") != null
                ? AdminTickets
                : AdminLogin
            }
          />
          <Route
            path="/partner"
            component={
              sessionStorage.getItem("partner_name") != null
                ? PartnerDashboard
                : PartnerLogin
            }
          />
          <Route
            path="/partnershift"
            component={
              sessionStorage.getItem("partner_name") != null
                ? PartnerShift
                : PartnerLogin
            }
          />
          <Route
            path="/partnergamestat"
            component={
              sessionStorage.getItem("partner_name") != null
                ? PartnerGameStat
                : PartnerLogin
            }
          />

          <Route
            path="/partnertickets"
            component={
              sessionStorage.getItem("partner_name") != null
                ? PartnerTickets
                : PartnerLogin
            }
          />

          {/* Spinner  Router */}
          <SpinnerAppRoute></SpinnerAppRoute>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
