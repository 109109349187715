import React, { useState, useEffect, forwardRef, useRef } from 'react';
import '../../resources/Keno/css/admin.css';

import Navbar from './navbars/NavbarAdmin';
import Dashboard from './admin_components/Dashboard';
import Shift from './admin_components/Shift';
import GameStat from './admin_components/GameStat';
import Settings from './admin_components/Settings';
import People from './admin_components/People';
import Tickets from './admin_components/Tickets';

const { base_url } = require("../../constants/index.js")



const AdminSettings= () => {

    const [currentPage, setCurrentPage] = useState("dashboard");


    return (
      <div className = "admin_container" style = {{ background: "#D3D3D3", minHeight: "100vh" }}>
    <React.Fragment>
        <Navbar currentPage = "adminsettings" />
    </React.Fragment>
    <Settings />
    </div>
    ); 

}
export default AdminSettings;