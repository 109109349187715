import React, { useState, useEffect, forwardRef, useRef} from 'react';
import '../../../resources/Keno/css/admin.css';
import { FaCheck, FaSignOutAlt, FaPrint, FaTimes, FaSearch, FaIconDashboard, FaExclamationCircle, FaShoppingCart, FaCoins } from 'react-icons/fa';


//import Navbar from '../navbars/Navbar';
const calendar_logo = require('../../../resources/Keno/img/icons-calendar.png');
const payIcon = require('../../../resources/Keno/img/pay.png');


const { base_url } = require("../../../constants/index.js")



const Dashboard = () => {

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [isError, setIsError] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [yesterdayToday, setYesterdayToday] = useState([]);
   

    

  const handle_yesterday_today_stat = async () => {
   //  e.preventDefault();
  
  const res = await fetch(`${base_url}all_tables/users/yesterdaytodaystat`, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    
  }).then(response => {
    if (response.ok) {
      response.json().then(json => {
        
          // setTm(json);
           console.log(json);
           setYesterdayToday(json);
      
      });
    } else {
      
    }
  }).catch((err) => {
        console.log("connection not working") ;
  });

 
}

const stat_report_by_day = async (from, to ) => {
  const x = new Date(from);
  const y = new Date(to);
  if(x <= y && from != "" && to != ""){
    setIsError(false);
    const res = await fetch(`${base_url}all_tables/users/statreportbyday`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({from_date: from, to_date: to})
    }).then(response => {
      if (response.ok) {
        response.json().then(json => {
          
            // setTm(json);
             console.log(json);
             setFetchedData(json);
        
        });
      } else {
        setIsError(true);
      }
    }).catch((err) => {
      setIsError(true);
          console.log("connection not working") ;
    });
  }
  
  else{
    setIsError(true);
  }
}
useEffect(  () => {
 
 const d = new Date();
 
 const prev_d = new Date();
 
  //d.setDate(d.getDate() + 1);
 const d_str = d.toISOString().replace('-', '/').split('T')[0].replace('-', '/');
 prev_d.setDate(prev_d.getDate() - 6);
 const prev_d_str = prev_d.toISOString().replace('-', '/').split('T')[0].replace('-', '/');
// let text = d.toString();
//stat_report_by_day_cashier(prev_d_str, d_str);
setFromDate(prev_d.toISOString().substring(0,10));
 setToDate(d.toISOString().substring(0,10));
//stat_report_by_day(prev_d_str, d_str);
 stat_report_by_day(prev_d.toISOString().substring(0,10), d.toISOString().substring(0,10));

  //console.log(next_d_str.split("/")[1]+"/"+next_d_str.split("/")[2]+"/"+next_d_str.split("/")[0]);
  handle_yesterday_today_stat();
},[]);
   
    return (
    //   <div className = "admin_container" style = {{ background: "#D3D3D3", minHeight: "100vh" }}>
    // <React.Fragment>
    //     <Navbar handlePage = {change_page} />
    // </React.Fragment>
    <div>
        <div style = {{height: "150px", background: "#86A879", padding: "1.2rem 2rem"}}>  
        <span style = {{color: "#fff"}}><span style = {{color: "#dcdcdc"}}>Partner</span> / Dashboard</span><br></br>
        <span style = {{color: "#fff"}}>Stats</span>
        </div>
        <div className = "row admin_dv_row" style = {{marginTop: "-4rem" ,width: "100%",  padding: "0 0 0 2rem"}}>
            <div className = "col-lg-3 col-md-6 col-sm-12 col-xs-12 admin_dashboards_dv">
                  <div className = "admin_dashboards">
                    <div>
                  <span>Today's Total</span>
                  <br></br>
                    {
                      
                      <span style= {{fontWeight: "600"}}>{yesterdayToday.today_collected_money}</span>
                    }
                    <br></br>
                    <span 
                    style = {{color: (yesterdayToday.today_collected_money - yesterdayToday.yesterday_collected_money) >= 0 ? "green" : "red"}}
                    >{
                    yesterdayToday.today_collected_money - yesterdayToday.yesterday_collected_money
                    }</span>
                    &nbsp;
                    <span>Since yesterday</span>
                    </div>
                    <div>
                    {/* <img src={calendar_logo} className = "img_site_logo" style = {{width: "30px", height: "30px"}}/> */}
                    <span style = {{background: "violet" , padding: "20px", borderRadius: "50%"}}><FaCoins style = {{color: "#fff",width: "20px", height: "20px"}} /></span>
                    </div>
                    </div> 
            </div>
            <div className = "col-lg-3 col-md-6 col-sm-12 col-xs-12 admin_dashboards_dv">
            <div className = "admin_dashboards">
            <div>
                  <span>Today's Earning</span>
                  <br></br>
                    {
                      
                      <span style= {{fontWeight: "600"}}>{yesterdayToday.today_profit}
                      &nbsp;({yesterdayToday.today_collected_money > 0 ? Math.round((yesterdayToday.today_profit/yesterdayToday.today_collected_money)* 100) : '0'}%)
                      </span>
                    }
                    <br></br>
                    <span 
                    style = {{color: (yesterdayToday.today_profit - yesterdayToday.yesterday_profit) >= 0 ? "green" : "red"}}
                    >{
                    yesterdayToday.today_profit - yesterdayToday.yesterday_profit
                    }</span>
                    &nbsp;
                    <span>Since yesterday</span>
                    </div>
                    <div>
                    {/* <img src={calendar_logo} className = "img_site_logo" style = {{width: "30px", height: "30px"}}/> */}
                    <span style = {{background: "coral" , padding: "20px", borderRadius: "50%"}}><FaCoins style = {{color: "#fff",width: "20px", height: "20px"}} /></span>
                    </div>
            </div>
            </div>
            <div className = "col-lg-3 col-md-6 col-sm-12 col-xs-12 admin_dashboards_dv">
            <div className = "admin_dashboards">
            <div>
                  <span>Yesterday's Total</span>
                  <br></br>
                    {
                      
                      <span style= {{fontWeight: "600"}}>{yesterdayToday.yesterday_collected_money}</span>
                    }
                    <br></br>
                    <span 
                    style = {{color: (yesterdayToday.yesterday_collected_money - yesterdayToday.yesterday_profit) >= 0 ? "green" : "red"}}
                    >{
                    yesterdayToday.yesterday_collected_money - yesterdayToday.yesterday_profit
                    }</span>
                    &nbsp;
                    <span>Won by players</span>
                    </div>
                    <div>
                    {/* <img src={calendar_logo} className = "img_site_logo" style = {{width: "30px", height: "30px"}}/> */}
                    <span style = {{background: "orange" , padding: "20px", borderRadius: "50%"}}><FaShoppingCart style = {{color: "#fff",width: "20px", height: "20px"}} /></span>
                    </div>
            </div>
            </div>
            <div className = "col-lg-3 col-md-6 col-sm-12 col-xs-12 admin_dashboards_dv">
            <div className = "admin_dashboards">
            <div>
                  <span>Yesterday's Earning</span>
                  <br></br>
                    {
                      
                      <span style= {{fontWeight: "600"}}>{yesterdayToday.yesterday_profit}
                      &nbsp;({yesterdayToday.yesterday_collected_money > 0 ? Math.round((yesterdayToday.yesterday_profit/yesterdayToday.yesterday_collected_money)* 100) : '0'}%)
                      </span>
                    }
                    <br></br>
                    <span 
                    style = {{color: (yesterdayToday.yesterday_profit) >= 0 ? "green" : "red"}}
                    >{
                   yesterdayToday.yesterday_profit
                    }</span>
                    &nbsp;
                    <span style = {{color: "green"}}>birr profit</span>
                    </div>
                    <div>
                    {/* <img src={calendar_logo} className = "img_site_logo" style = {{width: "30px", height: "30px"}}/> */}
                    <span style = {{background: "purple" , padding: "20px", borderRadius: "50%"}}><FaShoppingCart style = {{color: "#fff", width: "20px", height: "20px"}} /></span>
                    </div>
            </div>
            </div>
          </div>
        

    {/* <div className = "row" style = {{ color: "#fff"}}>
      <div className = "col-lg-6">
        fdgdgdf
      </div>
      <div className = "col-lg-6">
        fdgdgdf
      </div>
      </div> */}
      <div className = "admin_rep" style = {{background: "#fff", borderRadius: "10px", marginBottom: "-60px"}}>
          <div className = "admin_date_range">
          <div  ><label className = "admin_rep_date_lbl">Select Report Date: </label></div>
          <div > <div style = {{position: "relative", display: "inline-block"}}>
            <input type="date" className = "admin_input_date" 
            value = {fromDate}
            onChange = {(e) => setFromDate(e.target.value)}
            />
          <label className = "admin_lbl">From</label></div> </div>
          <div > <div style = {{position: "relative", display: "inline-block"}}>
            <input type="date" className = "admin_input_date" 
            value = {toDate}
            onChange = {(e) => setToDate(e.target.value)}
            />
          <label className = "admin_lbl">To</label></div> </div>
          <div><button 
          onClick = {() => stat_report_by_day(fromDate, toDate)}
          ><FaSearch />&nbsp;Search</button></div>
          </div>
          <div className = "admin_report_tbl">
           {
            isError ?  <div style = {{color: "#666", fontSize: "18px", textAlign: "center", marginTop: "20px"}}>
              
              <FaExclamationCircle style= {{marginBottom: "5px"}} />&nbsp;There is an error in the date specified</div> : 
            <div>
              
                {/* { fetchedData.bet_money_total.map((item, i) => {
                  return <span>{fetchedData.bet_money_total[0]}</span>
                }) }  */}
                {/* <span>{fetchedData.bet_money_total.length}</span> */}
                <div className="table-responsive" style = {{marginTop: "20px"}}>
                      <table className="table admin_tbl_report">
                      <thead >
    <tr >
      <th  scope="col" >Date</th>
      <th  scope="col">Total Money</th>
      <th  scope="col">Winner's Money</th>
      <th  scope="col">Total Earning</th>
    </tr>
  </thead>
  <tbody >

    {fetchedData.bet_money_total && fetchedData.bet_money_total.map((item, index) => {
      let i = (fetchedData.bet_money_total.length - 1) - index;
      return <tr key = {i}>
        <td style = {{display : "flex", alignItems: "center", justifyContent: "flex-start"}}>
        <img src={calendar_logo} className = "img_site_logo" style = {{width: "30px", height: "30px", marginRight: "30px"}}/>
        <span><span style = {{fontWeight: "600"}}>{fetchedData.day_names[i]} </span>
        <br></br>
        <span style = {{fontSize: "14px", color: "#666"}}>{fetchedData.days_in_range[i]}</span></span>
        </td>
        
        <td >
        
          <span>
          <span>{fetchedData.bet_money_total[i].total_bet_money ? fetchedData.bet_money_total[i].total_bet_money : 0}</span>
        <br></br>
        <span style = {{fontSize: "14px", color: "#666"}}>Birr</span>
        </span>
        </td>
      <td>
        <>
        {fetchedData.win_money_total[i].total_win_money ? fetchedData.win_money_total[i].total_win_money : 0}
      {/* &nbsp;({40}) */}
      {/* {2/4} */}
       &nbsp;({ fetchedData.bet_money_total[i].total_bet_money ? fetchedData.win_money_total[i].total_win_money ? 
       Math.round((fetchedData.win_money_total[i].total_win_money / fetchedData.bet_money_total[i].total_bet_money)*100) : 
       Math.round((0 / fetchedData.bet_money_total[i].total_bet_money)*100) : fetchedData.win_money_total[i].total_win_money ?
       'Over' : 0
}%)
<br></br>
<span style = {{fontSize: "14px", color: "#666"}}>Birr</span>
</>
      </td>
      <td>
        <>
        {
        fetchedData.bet_money_total[i].total_bet_money ? fetchedData.win_money_total[i].total_win_money ?
        fetchedData.bet_money_total[i].total_bet_money - fetchedData.win_money_total[i].total_win_money : 
        fetchedData.bet_money_total[i].total_bet_money - 0 : fetchedData.win_money_total[i].total_win_money ? 
        0 - fetchedData.win_money_total[i].total_win_money : 0 - 0
      }
      &nbsp;({
      fetchedData.bet_money_total[i].total_bet_money ? fetchedData.win_money_total[i].total_win_money ? 
       Math.round(((fetchedData.bet_money_total[i].total_bet_money - fetchedData.win_money_total[i].total_win_money)*100) / fetchedData.bet_money_total[i].total_bet_money) : 
       Math.round(((fetchedData.bet_money_total[i].total_bet_money - 0) / fetchedData.bet_money_total[i].total_bet_money)*100) : fetchedData.win_money_total[i].total_win_money ?
       0 : 0
  
        }%)
        <br></br>
          <span style = {{fontSize: "14px", color: "#666"}}>Birr</span>
        </>
        </td></tr>
    })}
  </tbody>
                      </table>
                </div> 
                
              
            </div>
           }
          </div>
          
      </div>
    </div>
    ); 

}
export default Dashboard;