import React, { useState, useEffect, forwardRef, useRef } from "react";
import "../../../resources/Spinner/css/admin.css";
import {
  FaCheck,
  FaSignOutAlt,
  FaPrint,
  FaTimes,
  FaSearch,
  FaIconDashboard,
  FaExclamationCircle,
  FaShoppingCart,
  FaCoins,
} from "react-icons/fa";

//import Navbar from '../navbars/Navbar';
const calendar_logo = require("../../../resources/Spinner/img/icons-calendar.png");
const payIcon = require("../../../resources/Spinner/img/pay.png");

const { base_url } = require("../../../constants/index.js")

var current_game_id = 0;
var openStatus = "closed";

const Settings = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isError, setIsError] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [currentGameId, setCurrentGameId] = useState("NONE");
  const [isBetOpen, setIsBetOpen] = useState(true);
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [isFactoryDefaultClicked, setIsFactoryDefaultClicked] = useState(false);
  const [currentGameLevel, setCurrentGameLevel] = useState("0");
  const [currentGameLength, setCurrentGameLength] = useState("300000");
  const [currentOpenStatus, setCurrentOpenStatus] = useState("");
  const [currentOnhandMoney, setCurrentOnhandMoney] = useState("");
  const [factoryDefaultFeedback, setFactoryDefaultFeedback] = useState("");

  // const [numBets, setNumBets] = useState(0);
  // const [totalBets, setTotalBets] = useState(0);

  function get_current_game_level() {
    const url = `${base_url}all_tables/users/getgamelevel`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            //  console.log(json);
            setCurrentGameLevel(json.game_level);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }
  function change_game_level(new_level) {
    const url = `${base_url}all_tables/users/changegamelevel`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ newLevel: new_level }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            setCurrentGameLevel(json.game_level);
          });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.log("connection not working");
      });
  }

  function get_current_game_length() {
    const url = `${base_url}getgamelength`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            //  console.log(json);
            setCurrentGameLength(json.game_length);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }
  function change_game_length(new_length) {
    const url = `${base_url}changegamelength`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ newLength: new_length }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            setCurrentGameLength(json.game_length);
          });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.log("connection not working");
      });
  }

  function get_current_open_status() {
    const url = `${base_url}all_tables/users/getgamestatus`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // console.log(json);
            setCurrentOpenStatus(json.status);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }

  function change_open_status(to_status) {
    const url = `${base_url}all_tables/users/changegamestatus`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ toStatus: to_status }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            setCurrentOpenStatus(json.status);
          });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.log("connection not working");
      });
  }

  function factory_default() {
    const url = `${base_url}all_tables/users/factorydefault`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: {}
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            //setCurrentOpenStatus(json.status);
            setFactoryDefaultFeedback("Successfully reset to factory default");
            setIsFactoryDefaultClicked(false);
            setCurrentOnhandMoney(0);
          });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.log("connection not working");
      });
  }

  function get_onhand_cash() {
    const url = `${base_url}all_tables/users/getonhandcash`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // console.log(json);
            setCurrentOnhandMoney(json.amount_on_hand);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }
  function reset_onhand_cash() {
    const url = `${base_url}all_tables/users/resetonhandcash`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            setCurrentOnhandMoney(json.amount_on_hand);
            setIsResetClicked(false);
          });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.log("connection not working");
      });
  }

  function seconds_to_clock(duration) {
    var time = duration / 1000;
    // var hours = Math.floor(time / 3600);
    // time -= hours * 3600;

    var minutes = Math.floor(time / 60);
    time -= minutes * 60;

    var seconds = parseInt(time % 60, 10);

    //console.log(hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
    return (
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  }

  function count_total_bets() {
    const url = `${base_url}all_tables/users/counttotalbets`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ game_number: current_game_id }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            setFetchedData(json);
          });
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.log("connection not working");
      });
  }

  function fetch_data_intro() {
    //----------------------------fetching open status and time remaining---------------------------
    // const url_open_status = `${base_url}all_tables/users/getopenstatus`;
    const url_open_status = `${base_url}getopenstatus`;

    const res = fetch(url_open_status)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // console.log(json);
            if (json.open_status == "closed") {
              setTimeRemaining(0);

              openStatus = "closed";
              setIsBetOpen(false);
              //  console.log("to fetch data intro");
              fetch_data_intro();
            } else {
              setTimeRemaining(json.time_remaining);
              //openStatus = "open";
              // setIsBetOpen(true);
              // console.log("to fetch data first");
              fetch_data_first();
            }

            // console.log("fetched");
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
    //---------------------------end of fetching open status and time remaining---------------------
  }

  //the timer to fetch data one
  function fetch_data_first() {
    //----------------------------fetching open status and time remaining---------------------------
    // const url_open_status = `${base_url}all_tables/users/getopenstatus`;
    const url_open_status = `${base_url}getopenstatus`;

    const res = fetch(url_open_status)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // console.log(json);
            if (json.open_status == "closed") {
              setTimeRemaining(0);
              // start spinner

              openStatus = "closed";
              setIsBetOpen(false);
              fetch_data_first();
            } else {
              setTimeRemaining(json.time_remaining);
              if (openStatus != "open") {
                setCurrentGameId(json.current_game_id);
                current_game_id = json.current_game_id;
                setIsBetOpen(true);
                openStatus = "open";
              }
              setIsBetOpen(true);
              openStatus = "open";
              fetch_data_first();
              count_total_bets();
            }
            //console.log("to fetch data second");

            // console.log("fetched");
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
    //---------------------------end of fetching open status and time remaining---------------------
  }

  useEffect(() => {
    get_current_game_level();
    get_current_game_length();
    get_current_open_status();

    get_onhand_cash();
  }, []);

  return (
    <div style={{ marginBottom: "-600px" }}>
      <div
        style={{
          height: "150px",
          background: "#86A879",
          padding: "1.2rem 2rem",
        }}
      >
        <span style={{ color: "#fff" }}>
          <span style={{ color: "#dcdcdc" }}>Admin</span> / Settings
        </span>
        <br></br>
        <span style={{ color: "#fff" }}>Stats</span>
      </div>

      <div
        className="admin_rep_level"
        style={{ background: "#fff", borderRadius: "10px" }}
      >
        <div style={{ marginBottom: "10px" }}>
          CURRENT GAME LEVEL :{" "}
          <span style={{ color: "green" }}>
            {currentGameLevel == 0 ? "Free Lottery" : currentGameLevel}
            {currentGameLevel == 0 ? "" : "%"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "30px", fontWeight: "600" }}>
            GAME LEVEL
          </div>
          <div
            style={{ maxHeight: "160px", width: "150px", overflow: "auto" }}
            className="admin_button_container"
          >
            <div>
              <button onClick={() => change_game_level(1500)}>1500%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(1000)}>1000%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(800)}>800%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(600)}>600%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(500)}>500%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(400)}>400%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(300)}>300%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(250)}>250%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(200)}>200%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(190)}>190%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(180)}>180%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(170)}>170%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(160)}>160%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(150)}>150%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(140)}>140%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(130)}>130%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(120)}>120%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(110)}>110%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(100)}>100%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(95)}>95%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(90)}>90%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(85)}>85%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(80)}>80%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(75)}>75%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(70)}>70%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(65)}>65%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(60)}>60%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(55)}>55%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(50)}>50%</button>
            </div>
            <div>
              <button onClick={() => change_game_level(0)}>Free Lottery</button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="admin_game_length"
        style={{ background: "#fff", borderRadius: "10px" }}
      >
        <div style={{ marginBottom: "10px" }}>
          CURRENT GAME LENGTH :{" "}
          <span style={{ color: "green" }}>
            {currentGameLength == 60000
              ? "1 MIN"
              : currentGameLength == 30000
              ? "HALF MIN"
              : (currentGameLength / 60000).toString() + " MIN"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "30px", fontWeight: "600" }}>
            GAME LENGTH
          </div>
          <div
            style={{ maxHeight: "160px", width: "150px", overflow: "auto" }}
            className="admin_button_container"
          >
            <div>
              <button onClick={() => change_game_length(600000)}>
                10 MINS
              </button>
            </div>
            <div>
              <button onClick={() => change_game_length(540000)}>9 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(480000)}>8 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(420000)}>7 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(360000)}>6 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(300000)}>5 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(240000)}>4 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(180000)}>3 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(120000)}>2 MINS</button>
            </div>
            <div>
              <button onClick={() => change_game_length(60000)}>1 MIN</button>
            </div>
            <div>
              <button onClick={() => change_game_length(30000)}>
                HALF MIN
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="admin_count_bets">
        <div
          className="admin_count_bets_small"
          style={{
            background: "#fff",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>
            CURRENT GAME STATUS :{" "}
            <span
              style={{ color: currentOpenStatus == "open" ? "green" : "red" }}
            >
              {currentOpenStatus.toUpperCase()}
            </span>
          </span>
          <button
            onClick={
              currentOpenStatus == "open"
                ? () => change_open_status("closed")
                : () => change_open_status("open")
            }
            className="admin_btn_status_change"
          >
            {currentOpenStatus == "open" ? "CLOSE" : "OPEN"}
          </button>
        </div>
        <div
          className="admin_count_bets_small"
          style={{
            background: "#fff",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>
            CURRENT ONHAND MONEY :{" "}
            <span
              style={{ color: currentOnhandMoney < 20000 ? "green" : "red" }}
            >
              {currentOnhandMoney}
            </span>
          </span>
          <button
            // onClick =  {() => reset_onhand_cash()}
            onClick={() => setIsResetClicked(true)}
            className="admin_btn_status_change"
          >
            RESET
          </button>
        </div>
      </div>
      <div className="admin_factory_default">
        <div
          className="admin_factory_default_small"
          style={{
            background: "#fff",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            onClick={() => setIsFactoryDefaultClicked(true)}
            className="admin_btn_status_change"
          >
            Factory Default
          </button>
          <span>{factoryDefaultFeedback}</span>
        </div>
        <div
          className="admin_factory_default_small"
          style={{
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "transparent" }}>placeholder</span>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          display: isResetClicked ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",

          background: "rgba(0,0,0,0.8)",
          zIndex: "500",
        }}
      >
        {/* <h2 style = {{color: "#fff"}}>BET IS CLOSED</h2> */}
        <div
          style={{ padding: "20px", background: "#fff", borderRadius: "5px" }}
        >
          <span>Are you sure you want to reset?</span>
          <br></br>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              onClick={() => reset_onhand_cash()}
              className="admin_btn_status_change"
              style={{ marginRight: "5px" }}
            >
              Yes
            </button>
            <button
              onClick={() => setIsResetClicked(false)}
              className="admin_btn_status_change"
              style={{ marginLeft: "5px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          display: isFactoryDefaultClicked ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",

          background: "rgba(0,0,0,0.8)",
          zIndex: "500",
        }}
      >
        {/* <h2 style = {{color: "#fff"}}>BET IS CLOSED</h2> */}
        <div
          style={{ padding: "20px", background: "#fff", borderRadius: "5px" }}
        >
          <span>Are you sure you want to factory default?</span>
          <ul>
            <li>Please do this when you are not busy.</li>
            <li>Make sure all of the windows are closed</li>
            <li>
              You can reopen the windows once you are done factory defaulting
            </li>
            <li>All windows have to be restarted</li>
          </ul>
          <br></br>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              onClick={() => factory_default()}
              className="admin_btn_status_change"
              style={{ marginRight: "5px" }}
            >
              Yes
            </button>
            <button
              onClick={() => setIsFactoryDefaultClicked(false)}
              className="admin_btn_status_change"
              style={{ marginLeft: "5px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;
